<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_ChooseAccount',])"></div>
      {{$t('requestFunds_ChooseAccount.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_WhereSend',])"></div>
        <DefaultSelect
                :options="options"
                :label="$t('requestFunds_WhereSend.localization_value.value')"
                v-bind:class="{'ui-no-valid': RF.validation.accountType}"
                :errorTxt="$t(`${RF.validationTranslate.accountType}.localization_value.value`)"
                :error="RF.validation.accountType"
                :otherValue="'accounts'"
                :selected="RF.data.accountType"
                :disabled="this.RF.getOldStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value || blockChange"
                @change="changeAccountType"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {requestFunds} from "../../../../../../mixins/requestFundsMixins/requestFunds";
  import {
    ACCOUNT_BANK_TYPE, ACCOUNT_TYPE,
    REQUEST_FUNDS_STATUSES,
    REQUEST_FUNDS_TYPES
  } from "../../../../../../staticData/staticVariables";
  export default {
    name: "ChooseAccount",
    components: {DefaultSelect},

    mixins: [requestFunds],

    props: {
      RF: Object,
    },

    computed: {
      balanceType() {
        return this.RF.data.balanceType
      },
    },

    watch: {
      userId(){
        this.initAccounts()
      },
      balanceType(){
        this.checkBalanceType()
      },
    },

    data() {
      return {
        options: [],
        REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,
        blockChange: false,
      }
    },

    mounted() {
      if(this.RF.data.User.getUserId()){
        this.initAccounts()
      }
    },

    methods: {
      initAccounts(){
        let userId = this.RF.data.User.getUserId()
        let inArchive = 0
        let verify = 1
        let type = this.RF.getType() === REQUEST_FUNDS_TYPES.INDIVIDUAL ?
            `${ACCOUNT_BANK_TYPE.WORLD_BANK.id},${ACCOUNT_BANK_TYPE.UKRAINIAN_BANK.id},${ACCOUNT_BANK_TYPE.CUSTOM_ACCOUNT.id}` :
            `${ACCOUNT_BANK_TYPE.CUSTOM_BANK.id}`

        this.$store.dispatch('fetchAccount',
          `?filter[user_id]=${userId}&filter[bank_type_id]=${type}&filter[in_archive]=${inArchive}&filter[verify]=${verify}&limit=1000`)
          .then(response => {
            console.log(111, this.getRespPaginateData(response))
            this.options = this.getRespPaginateData(response)
        })
      },

      changeAccountType(val){
        this.RF.setAccountType(val)
      },

      checkBalanceType() {
        if(this.RF.data.balanceType.id === this.RF.data.balanceTypes.payoneerBalance.id) {
          if(!this._.find(this.options, {id: -1})) {
            this.options.unshift({id: -1, type_id: ACCOUNT_TYPE.INDIVIDUAL, bank_id: null, bank: {currentTranslate: {name: 'On base balance'}}})
          }

          if(Number(this.RF.data.User.user.balance) < 0) {
            this.blockChange = true
            this.changeAccountType(this._.first(this.options))
            this.RF.data.selectSum = parseFloat(this.RF.data.User.user.balance * -1).toFixed(2)

            let text = {
              title: 'common_onlyBaseBalanceTitle',
              txt: 'common_onlyBaseBalance',
              // yes: 'common_confirm',
              no: 'common_close'
            }

            let confirm = () => {
              this.$store.commit('setConfirmDeletePopup', false)
              return true
            }
            this.deleteFunc(text, confirm)
          }

        } else {
          if(this._.find(this.options, {id: -1})) {
            let index = this._.findIndex(this.options, {id: -1})
            this.options.splice(index, 1)
          }
        }
      },
    }

  }
</script>

<style scoped>

</style>

<template>
  <div>
    <RequestFundsHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        @toggleFilter="toggleFilter"
        @filter="$emit('filter')"
        @changeTab="(data) => $emit('changeTab', data)"
        @reload="$emit('reload')"
        @selectAll="selectAll"
        @multipleRequestFunds="type => $emit('multipleRequestFunds', type)"
    />

    <div class="table-filter-wrap">

      <RequestFundsFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => $emit('changeFilter', data)"
          @resetFilter="$emit('resetFilter')"
      />

      <RequestFundsTable
          :countFilterParams="countFilterParams"
          :selectedNow="selectedNow"
          :sortParams="sortParams"
          @changeSort="data => $emit('changeSort', data)"
          @showMore="$emit('showMore')"
          @resetFilter="$emit('resetFilter')"
          @reload="$emit('reload')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @sendRequestFunds="id => $emit('sendRequestFunds', id)"
          @sendRequestFundsNoAPI="id => $emit('sendRequestFundsNoAPI', id)"
      />
    </div>
  </div>
</template>

<script>

  import RequestFundsHead from "./RequestFundsHead/RequestFundsHead";
  import RequestFundsFilter from "./RequestFundsFilter/RequestFundsFilter";
  import RequestFundsTable from "./RequestFundsTable/RequestFundsTable";

  export default {
    name: "RequestFundsTableUser",

    components: {
      RequestFundsHead,
      RequestFundsFilter,
      RequestFundsTable,
    },

    props: {
      countFilterParams: Number,
      filterGetParams: Object,
      sortParams: Object,
    },

    data(){
      return{
        showFilter: false,
        selectedNow: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },

      selectAll(val) {
        this.selectedNow = val
      },
    }
  }
</script>

<style scoped>

</style>

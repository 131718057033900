<template>
  <div class="detail-page">
    <div class="detail-page__left">
      <CardLeftBlock
          :name="'Shipment ID'"
          :value="'#180378'"
          :backgroundImage="'product-admin'"
      >
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.financeRequestFunds" class="order-create__head-back">
                  <LinkBack
                      :value="'Back to Funds'"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-brown">
              Request Fund
            </div>
            <div class="order-create__head-txt mb-0">
              Please, fill out all the information below to request funds
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">
            <div class="section-label">
              Choose account
            </div>
            <div class="custom-row">
              <div class="custom-col custom-col--16 custom-col--xs-50 custom-col--es-100 custom-col--md-33 custom-col--lg-20">
                <RadioDefault
                    :label="'PayPal'"
                    :name="'account'"
                    :card="true"
                >
                  <template slot="card">
                    <BankCardBlock
                        class="mt-2"
                        :type="'paypal'"
                    />
                  </template>
                </RadioDefault>

              </div>
              <div class="custom-col custom-col--16 custom-col--xs-50 custom-col--es-100 custom-col--md-33 custom-col--lg-20">
                <RadioDefault
                  :label="'Privat Bank'"
                  :name="'account'"
                  :card="true"
                >
                  <template slot="card">
                    <BankCardBlock
                        class="mt-2"
                        :type="'visa'"
                    />
                  </template>
                </RadioDefault>
              </div>
              <div class="custom-col custom-col--16 custom-col--xs-50 custom-col--es-100 custom-col--md-33 custom-col--lg-20">
                <RadioDefault
                  :label="'MonoBank'"
                  :name="'account'"
                  :card="true"
                >
                  <template slot="card">
                    <BankCardBlock
                        class="mt-2"
                        :type="'mastercard'"
                    />
                  </template>
                </RadioDefault>


              </div>
            </div>

            <div class="section-label">
              Funds Details
            </div>

            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col mb-0">
                <div class="custom-row">
                  <div class="custom-col custom-col--66 custom-col--md-100 mb-0">
                    <div class="soft-text">
                      Available Sum to Withdraw is
                      <span>$250</span>.
                    </div>
                    <div class="soft-text">
                      You may either enter the sum and the system will suggest you the transactions according
                      to the sum or vise a versa — choose the transactions and the system will count the sum to withdraw
                    </div>
                  </div>
                </div>
              </div>

              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--xs-100">
                <RadioDefault
                    :label="'No VAT'"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(0)"
                    :value="true"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--xs-100">
                <RadioDefault
                    :label="'VAT (20%)'"
                    :name="'insuranceRadio'"
                    @change.native="changeRadio(1)"
                />

              </div>
              <div class="order-create__col custom-col custom-col--66 custom-col--md-100 custom-col--xs-100 mb-0"></div>

              <div class="order-create__col custom-col custom-col--66 custom-col--md-100 custom-col--xs-100 ">
                <MultiSelect
                    :options="options"
                    :optionsLabel="'display_name'"
                    :selected="''"
                    :label="'Transactions'"
                    :searchable="false"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-33 custom-col--xs-50 flex-column">
                <InputSum
                    :label="'Select Sum'"
                    :icoType="'dollar'"
                    :placeholder="'100'"
                />

                <div class="position-relative mt-1 mb-2">
                  <div class="soft-text font-weight-normal ws-nowrap position-absolute"  v-if="radioVal === 1">
                    - 20% VAT = <span>$190 to withd raw</span>
                  </div>
                </div>
              </div>
            </div>


            <div class="section-label">
              Comment
            </div>
            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--66 custom-col--md-100">
                <TextareaDefault
                    :label="'Comment'"
                    :value="text"
                />
              </div>
            </div>

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeRequestFunds"
                           class="site-link site-link--alt"
              >
                Cancel
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto">
              <MainButton
                  class="order-create__footer-btn-i"
                  :value="'Request'"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum.vue";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import BankCardBlock from "../../../../../coreComponents/BankCardBlock/BankCardBlock";
  import MultiSelect from "../../../../../UI/selectiones/MultiSelect/MultiSelect";


  export default {
    name: "RequestFundsBusinessUser",

    components: {
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      InputSum,
      RadioDefault,
      TextareaDefault,
      BankCardBlock,
      MultiSelect,
    },

    data() {
      return{
        lotNumber: '',
        transaction: '#5496823: $1.57',
        valueSum:'',

        options: [
            '#49357: $100.4',
            '#49357: $10.4',
            '#49357: $59.2'
        ],

        radioVal: 0,

        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in blandit nisl. Integer eu fermentum neque. Phasellus justo risus, placerat sed tortor non, tristique lacinia elit. Aenean mattis finibus augue sed gravida. Quisque et varius nisl. Nunc mauris dui, ullamcorper eget nunc nec, consequat bibendum nunc'
      }
    },

    methods:{

      changeValueSum(data){
        this.valueSum = data
      },

      changeImg(files) {
        this.files = files
      },

      changeRadio(val){
        this.radioVal = val
      }
    }
  }
</script>

<style lang="scss">

  .hide-upc{
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps{
    max-width: 625px;
  }

</style>

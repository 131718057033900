<template>
  <modal
          v-if="!loading"
          class="invoice-funds-popup"
          @close="$emit('close')"
  >
    <template slot="header">
      <div v-if="!edit"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_CreateItem',])"></div>
        {{$t('common_CreateItem.localization_value.value')}}
      </div>
      <div v-else
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_ChangeItem',])"></div>
        {{$t('common_ChangeItem.localization_value.value')}}
      </div>
    </template>
    <template slot="body">
      <div class="profiles-package__content">

        <div class="profiles-package__row custom-row">
          <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'product_InvoiceSeller',
                      ])"></div>
            <DefaultSelect
                :options="optionsSeller"
                :label="$t('product_InvoiceSeller.localization_value.value')"
                :optionsLabel="'fop_name'"
                :selected="RFI.data.InvoiceSeller.data.Order.data.orderOBJ"
                @change="changeSeller"
            />
            <div class="order-create__product-add-to-profile"
                 v-if="!RFI.data.InvoiceSeller.data.Order.data.orderOBJ && !isAdmin"
                 @click="addToProfileSeller">

              <template>
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                      <span>
                        <AddToProfileIco/>
                      </span>
                  <template slot="popover">
                    {{$t('common_AddProfile.localization_value.value')}}
                  </template>
                </v-popover>
              </template>
            </div>
          </div>

          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_fopName',])"></div>
            <DefaultInput
                :label="$t('common_fopName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': RFI.data.InvoiceSeller.validation.fopName}"
                :errorTxt="$t(`${RFI.data.InvoiceSeller.validationTranslate.fopName}.localization_value.value`)"
                :error="RFI.data.InvoiceSeller.validation.fopName"
                v-model="RFI.data.InvoiceSeller.data.fopName"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_bankName',])"></div>
            <DefaultInput
                :label="$t('common_bankName.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': RFI.data.InvoiceSeller.validation.bankName}"
                :errorTxt="$t(`${RFI.data.InvoiceSeller.validationTranslate.bankName}.localization_value.value`)"
                :error="RFI.data.InvoiceSeller.validation.bankName"
                v-model="RFI.data.InvoiceSeller.data.bankName"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_bankSwift',])"></div>
            <DefaultInput
                :label="$t('common_bankSwift.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': RFI.data.InvoiceSeller.validation.bankSwift}"
                :errorTxt="$t(`${RFI.data.InvoiceSeller.validationTranslate.bankSwift}.localization_value.value`)"
                :error="RFI.data.InvoiceSeller.validation.bankSwift"
                v-model="RFI.data.InvoiceSeller.data.bankSwift"
            />
          </div>
          <div class="profiles-package__col custom-col custom-col--50"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_bankAccount',])"></div>
            <DefaultInput
                :label="$t('common_bankAccount.localization_value.value')"
                :type="'text'"
                v-bind:class="{'ui-no-valid': RFI.data.InvoiceSeller.validation.bankAccount}"
                :errorTxt="$t(`${RFI.data.InvoiceSeller.validationTranslate.bankAccount}.localization_value.value`)"
                :error="RFI.data.InvoiceSeller.validation.bankAccount"
                v-model="RFI.data.InvoiceSeller.data.bankAccount"
            />
          </div>
          

          <div class="profiles-package__col custom-col">
            <template v-for="(item, index) in RFI.data.simpleProducts">
              <SimpleProductItem
                  :optionsLabel="'label'"
                  :key="index"
                  :item="item"
                  :optionsProduct="optionsProduct"
                  :canRemoveProduct="RFI.data.simpleProducts.length > 1"
                  @changeSimpleProduct="(val) => changeSimpleProduct(val, item)"
                  @changeValueSum="(val) => changeValueSum(val, item)"
                  @changeValueCount="(val) => changeValueCount(val, item)"
                  @addToProfile="addToProfile(item)"
                  @removeProduct="removeProduct(index)"
                  @getProducts="getProducts"
              />
            </template>

            <div class="order-create__product-btn add-product-btn-wrap"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}" >
              <div class="admin-edit" @click="editTranslate(['common_AddProduct',])"></div>
              <div class="order-create__product-btn-i add-product-btn site-link" @click="addProduct">
                + {{ $t('common_AddProduct.localization_value.value') }}
              </div>
            </div>

          </div>


          <div class="profiles-package__col custom-col">
            <div class="order-create__total"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
              'common_TotalShippingCost',
            ])"></div>
              {{ $t('common_TotalShippingCost.localization_value.value') }}
              <span>${{ calculatePrice }}</span>
            </div>
          </div>


        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel', 'common_create'])"></div>
        <MainButton
            class="custom-popup__btn-i secondary mr-3 mt-2"
            :value="$t('common_cancel.localization_value.value')"
            @click.native="$emit('close')"
            v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
        />
        <MainButton
            v-if="edit"
            class="custom-popup__btn-i mr-3 mt-2"
            :value="$t('common_edit.localization_value.value')"
            @click.native="save"
            v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
        />
        <MainButton
            v-else
            class="custom-popup__btn-i mr-3 mt-2"
            :value="$t('common_create.localization_value.value')"
            @click.native="save"
            v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
        />
        <MainButton
            v-if="edit"
            class="custom-popup__btn-i mt-2"
            :value="$t('common_print.localization_value.value')"
            @click.native="printInvoice"
            v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import SimpleProductItem
    from "@/components/modules/RequestFundsModule/popups/FundsInvoicePopup/SimpleProductItem/SimpleProductItem";
  import {RequestFundsInvoice} from "@/components/modules/RequestFundsModule/models/RequestFundsInvoice";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
  import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
  import AddToProfileIco from '../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
  import MainButton from "@/components/UI/buttons/MainButton/MainButton";


  export default {
    name: "FundsInvoicePopup",
    components: {
      MainButton,
      DefaultSelect,
      DefaultInput,
      SimpleProductItem,
      Modal,
      AddToProfileIco,
    },

    props: {
      idFund: Number,
      idInvoice: Number,
      item: Object,
    },

    computed: {
      edit() {
        return this.idInvoice !== null
      },

      calculatePrice() {
        let total = 0

        this.RFI.data.simpleProducts.map(product => {
          total += Number(product.data.totalSum)
        })

        return parseFloat(total).toFixed(2)
      }
    },

    data() {
      return {
        RFI: new RequestFundsInvoice(),
        optionsSeller: [],
        optionsProduct: [],
        loading: false,
      }
    },

    mounted() {

      if(this.idInvoice !== null) {
        this.loading = true
        this.$store.dispatch('getRequestFundsInvoice', this.idInvoice).then((response) => {
          this.RFI.setItem(this.getRespData(response)?.balance_output_invoice)
          this.loading = false
        })

      }

      this.$store.dispatch('fetchInvoiceSeller', '?limit=1000000').then((response) => {
        this.optionsSeller = this.getRespPaginateData(response)
      })

      this.$store.dispatch('fetchInvoiceProduct', '?limit=1000000').then((response) => {
        this.optionsProduct = this.getRespPaginateData(response)
      })
    },

    methods: {

      getProducts(val = false, loading, item){

        this.$store.dispatch('fetchInvoiceProduct', `?limit=1000000&filter[item_name]=${val}`).then((response) => {
          this.optionsProduct = this.getRespPaginateData(response)

          if(this.optionsProduct.length === 0 && val) {
            item.data.productObject = {
              'label': val
            }
            item.setName(val)
          }

          if(loading)
            loading(false)
        })
      },

      printInvoice() {
        this.save().then((id) => {
          if(Number.isInteger(id)) {
            this.$store.dispatch('printRequestFundsInvoice', id).then((response) => {
              this.globalDownloadBase64File(response.data.data.mimeType, response.data.data.fileContentBase64, response.data.data.fileName)
            })
          }
        })
      },

      async save() {
        let valid = true
        this.RFI.data.simpleProducts.map(item => {
          if (!item.dataValidation({
            price: true,
            count: true,
            name: true,
            nameCyrillicAndLatin: true,
          })) valid = false
        })
        console.log(this.RFI.data.simpleProducts);

        if (!this.RFI.data.InvoiceSeller.itemValidate(false) || !valid) return

        let data = this.RFI.prepareData()
        let dispatchType = 'createRequestFundsInvoice'

        data['balance_output_id'] = this.idFund

        if(this.edit) {
          data = {
            data: data,
            id: this.idInvoice
          }
          dispatchType = 'updateRequestFundsInvoice'
        }

        return this.$store.dispatch(dispatchType, data).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              if (this.edit) {
                this.openNotify('success', 'common_notificationRecordChanged')
              } else {
                this.openNotify('success', 'common_notificationRecordCreated')
                if(Number.isInteger(this.getRespData(response)?.balance_output_invoice?.id)) {
                  this.$store.dispatch('printRequestFundsInvoice', this.getRespData(response)?.balance_output_invoice?.id).then((response) => {
                    this.globalDownloadBase64File(response.data.data.mimeType, response.data.data.fileContentBase64, response.data.data.fileName)
                  })
                }
              }
              this.$emit('reload')
              this.$emit('close')
              return this.getRespData(response)?.balance_output_invoice?.id
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })

      },

      changeSeller(val) {
        this.RFI.data.InvoiceSeller.setItem(val)
        this.RFI.data.InvoiceSeller.data.Order.data.orderOBJ = val
      },

      changeSimpleProduct(val, item) {
        item.setItem(val)
        item.setName(val.item_name)
        item.totalSumComputation()
      },

      changeValueSum(val, item) {
        item.setPrice(val)
        item.totalSumComputation()
      },

      changeValueCount(val, item) {
        item.setCount(val)
        item.totalSumComputation()
      },

      addToProfile(item) {
        item.setUserId(this.$store.getters.getUserProfile.id)

        if (!item.dataValidation({
          price: true,
          count: true,
        })) return

        let data = item.prepareData()
        data['item_name'] = data['name']
        let dispatchType = 'createInvoiceProduct'

        this.$store.dispatch(dispatchType, data).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      addToProfileSeller() {
        this.RFI.data.InvoiceSeller.data.User.setUserId(this.$store.getters.getUserProfile.id)

        if (!this.RFI.data.InvoiceSeller.itemValidate(false)) return

        let data = this.RFI.data.InvoiceSeller.prepareData()

        let dispatchType = 'createInvoiceSeller'

        this.$store.dispatch(dispatchType, data).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },

      addProduct() {
        this.RFI.addProduct()
      },

      removeProduct(index) {
        this.RFI.removeProduct(index)
      },

    }
  }

</script>

<style lang="scss">
 .invoice-funds-popup .modal-component__inner {
   max-width: 800px;
 }
</style>

<template>
  <div class="bank-card-block">
    <div class="bank-card-block__img"
        :class="{
          'paypal' : type==='paypal',
          'mastercard' : type==='mastercard',
          'visa' : type==='visa',
        }"
    >

    </div>
  </div>
</template>

<script>
  export default {
    name: "BankCardBlock",
    props: [
      'type'
    ],
  }
</script>

<style lang="scss">
  .bank-card-block{

    &__img{
      width: 130px;
      height: 78px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;


      &.paypal{
        background-image: url("../../../assets/img/common/card-paypal.svg");
      }
      &.mastercard{
        background-image: url("../../../assets/img/common/card-master.svg");
      }
      &.visa{
        background-image: url("../../../assets/img/common/card-visa.svg");
      }
    }

  }
</style>
import {checkValid} from "../../globalModels/functions/ModelsValidate";
import {CUSTOMERS_PAYMENTS_STATUSES} from "../../../staticData/staticVariables";


export function NewPayments() {

  this.checkValid = checkValid

  this.newPayments = {
    id: -1,
    user: {},
    userId: '',
    amount: 0,
    comment: '',
    status: '',
    historyOfChanged: null,
  }

  this.validation = {
    user: false,
    amount: false,
    comment: false,
  }

  this.validationTranslate = {
    user: '',
    amount: '',
    comment: '',
  }

  this.validationTxt = {
    user: false,
    amount: false,
    comment: false,
  }

}


/*****************   SETTERS   ******************/

NewPayments.prototype.setUser = function(val){
  this.newPayments.user = val;
  this.newPayments.userId = val.id;
}

NewPayments.prototype.setAmount = function(val){
  this.newPayments.amount = val;
}

NewPayments.prototype.setComment = function(val){
  this.newPayments.comment = val;
}

NewPayments.prototype.setHistoryOfChanged = function(val){
  this.newPayments.historyOfChanged = val;
}

NewPayments.prototype.setNewPaymentItem = function(val){
  this.newPayments.id = val.id;
  this.newPayments.user = val.user;
  this.newPayments.userId = val.user.id;
  this.newPayments.amount = val.payment_total;
  this.newPayments.comment = val.payment_transaction_detail.description;
  this.newPayments.status = val.status;
  this.newPayments.historyOfChanged = val.activityUUID;
}

/*****************   GETTERS   ******************/
NewPayments.prototype.getUser = function(){
  return this.newPayments.user;
}

NewPayments.prototype.getAmount = function(){
  return this.newPayments.amount;
}

NewPayments.prototype.getComment = function(){
  return this.newPayments.comment;
}

NewPayments.prototype.getHistoryOfChanged = function(){
  return this.newPayments.historyOfChanged;
}


/*****************   VALIDATION   ******************/

NewPayments.prototype.newPaymentsValidate = function(){

  let validationItems = {
    user: this.newPayments.userId,
    amount: this.newPayments.amount,
  }

  let validationOptions = {
    user: {type: ['empty']},
    amount: {type: ['numeric', 'empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}


/****************    PREPARE    ********************/

NewPayments.prototype.newPaymentsPrepare = function(deferred = false){
  let data = {
    "comment" : this.newPayments.comment
  }
  if (this.newPayments.status !== CUSTOMERS_PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS) {
    data["deferred"] = deferred
    data["amount"] = this.newPayments.amount
    data["user_id"] = this.newPayments.userId
  }
  return data
}





import {CUSTOMERS_PAYMENTS_STATUSES as PAYMENTS_STATUSES} from "../../staticData/staticVariables";
import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";

export const newPaymentsMixin = {

  mixins: [checkAccess],

  methods: {

    async createPayment(pay = false, deferred = false) {
      if(!this.pm.newPaymentsValidate()) return

      let payPayment = deferred || pay

      if(payPayment) {
        await this.$store.dispatch('checkExpired2faAt').then(response => {
          payPayment = response.status
        })
      }

      let data = this.pm.newPaymentsPrepare(payPayment)

      return this.$store.dispatch('createNewPayments', data).then((response) => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(!payPayment && (deferred || pay)){
              this.newPaymentAccess(response.data.data.transaction.id)
              return
            }

            this.$router.push(this.$store.getters.GET_PATHS.financeNewPayment)
            this.openNotify('success', 'common_notificationRecordCreated')

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        // if (this._.has(response, 'data') &&
        //   response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //   if(!payPayment && (deferred || pay)){
        //     this.newPaymentAccess(response.data.data.transaction.id)
        //     return
        //   }
        //
        //   // if(pay) {
        //   this.$router.push(this.$store.getters.GET_PATHS.financeNewPayment)
        //   // }
        //   this.openNotify('success', 'common_notificationRecordCreated')
        //   // else {
        //     // this.payPaymentHandle(response.data.data.transaction.id, {goToTable: true, create: true})
        //   // }
        //
        //
        // } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //   alert('VALIDATION_ERROR')
        // } else {
        //   this.openNotify('error', 'common_notificationUndefinedError')
        // }

        return response
      })
    },

    async createRemainedPayment(pay = false, deferred = false, notRedirect = false) {
      if(!this.pm.newPaymentsValidate()) return

      let payPayment = deferred || pay

      if(payPayment) {
        await this.$store.dispatch('checkExpired2faAt').then(response => {
          payPayment = response.status
        })
      }

      let data = this.pm.newPaymentsPrepare(payPayment)

      return this.$store.dispatch('createRequestFundsRemainedTrns', data).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(!payPayment && (deferred || pay)){
              this.newPaymentAccess(response.data.data.transaction.id)
              return
            }

            if(notRedirect) {
              this.openNotify('success', 'common_notificationRecordCreated')
              return response
            }

            this.$router.push(this.$store.getters.GET_PATHS.cusPayments + '?page=1&count=25&type=balanceOutput')
            this.openNotify('success', 'common_notificationRecordCreated')
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        // if (this._.has(response, 'data') &&
        //   response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //   if(!payPayment && (deferred || pay)){
        //     this.newPaymentAccess(response.data.data.transaction.id)
        //     return
        //   }
        //
        //   // if(pay) {
        //   this.$router.push(this.$store.getters.GET_PATHS.cusPayments + '?page=1&count=25&type=balanceOutput')
        //   // }
        //   this.openNotify('success', 'common_notificationRecordCreated')
        //   // else {
        //     // this.payPaymentHandle(response.data.data.transaction.id, {goToTable: true, create: true})
        //   // }
        //
        //
        // } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //   alert('VALIDATION_ERROR')
        // } else {
        //   this.openNotify('error', 'common_notificationUndefinedError')
        // }

        return response
      })
    },

    payPaymentHandle(id, {goToTable = false, create = false}){

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.payPayment(id, {goToTable: goToTable, create: create})

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    async payPayment(id, {goToTable = false, create = false}) {

      let assess = true
      await this.newPaymentAccess(id).then(response => {
        assess = response
      })

      if(!assess) return

      return this.$store.dispatch('payNewPayments', id).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (create) {
              this.openNotify('success', 'common_notificationRecordCreated')
            } else {
              this.openNotify('success', 'common_notificationRecordChanged')
            }

            if (goToTable) {
              setTimeout(() => {
                this.$router.push(this.$store.getters.GET_PATHS.financeNewPayment)
              }, 2000)
            } else {
              let item = this._.find(this.$store.getters.getNewPayments, {id: parseInt(id)})
              if(item){
                item.status = PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS
              }
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        return response

        // if (this._.has(response, 'data') &&
        //   response.data.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
        //
        //   if (create)
        //     this.openNotify('success', 'common_notificationRecordCreated')
        //   else
        //     this.openNotify('success', 'common_notificationRecordChanged')
        //
        //   if (goToTable)
        //     setTimeout(() => {
        //       this.$router.push(this.$store.getters.GET_PATHS.financeNewPayment)
        //     }, 2000)
        //   else {
        //     let item = this._.find(this.$store.getters.getNewPayments, {id: parseInt(id)})
        //     if(item){
        //       item.status = PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS
        //     }
        //   }
        //   return response
        //
        // } else if (response.response.data.status === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
        //   alert('VALIDATION_ERROR')
        // } else {
        //   this.$emit('filter')
        //   this.openNotify('error', 'common_notificationUndefinedError')
        // }
      })
    },
  }
}

<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter"
               @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['requestFunds_ID',])"></div>
        <DefaultInput
                :label="$t('requestFunds_ID.localization_value.value')"
                :type="'text'"
                v-model="id"
        />
      </div>
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['ukrTreasures_DateOfCreation'])"></div>
        <DatePickerDefault
                :label="$t('requestFunds_Date.localization_value.value')"
                v-model="date"
        >
          <template slot="body">
            <date-picker
                    v-model="date"
                    ref="datePicker"
                    valueType="format"
                    range
                    :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                    :format="'MM/DD/YY'"
                    :placeholder="'mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_user',])"></div>
        <SearchSelect
                :options="options"
                :label="$t('common_user.localization_value.value')"
                :optionsLabel="'email.contacts[0]'"
                :functionSearch="functionSearchUserFilter"
                :selected="userName"
                :typeSelect="'users'"
                :userEmail="true"
                @change="changeUsersFilter"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_Account',])"></div>
        <SearchSelect
                :options="optionsAccounts"
                :label="$t('requestFunds_Account.localization_value.value')"
                :typeSelect="'accounts'"
                :functionSearch="(value, loading) => functionSearch(value, loading, 'accounts')"
                :selected="accountName"
                :userEmail="true"
                @change="changeAccountsFilter"
        />
      </div>


      <div class="table-filter__item"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['requestFunds_Total',])"></div>
        <DefaultInput
                :label="$t('requestFunds_Total.localization_value.value')"
                :type="'text'"
                v-model="total"
        />
      </div>

      <div class="table-filter__item"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['requestFunds_Partial',])"></div>
        <DefaultInput
                :label="$t('requestFunds_Partial.localization_value.value')"
                :type="'text'"
                v-model="partial"
        />
      </div>
      <div class="table-filter__item"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['requestFunds_Transactions',])"></div>
        <DefaultInput
                :label="$t('requestFunds_Transactions.localization_value.value')"
                :type="'text'"
                v-model="transaction"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_accountBank',])"></div>
        <DefaultSelect
            :options="optionsAccountBanks"
            :label="$t('requestFunds_accountBank.localization_value.value')"
            :optionsLabel="'name'"
            :selected="accoutBankSelected"
            @change="changeBank"
        />
      </div>

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_accountBalanceType',])"></div>
        <DefaultSelect
            :options="optionsBalanceType"
            :label="$t('requestFunds_accountBalanceType.localization_value.value')"
            :optionsLabel="'label'"
            :selected="getSelectedType"
            @change="changeType"
        />
      </div>

      <!--<div class="table-filter__item"-->
                         <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['requestFunds_YourComment',])"></div>-->
        <!--<DefaultInput-->
                <!--:label="$t('requestFunds_YourComment.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="comment"-->
        <!--/>-->
      <!--</div>-->

      <!--<div class="table-filter__item"-->
                         <!--v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
                      <!--<div class="admin-edit" @click="editTranslate(['requestFunds_AdminComment',])"></div>-->
        <!--<DefaultInput-->
                <!--:label="$t('requestFunds_AdminComment.localization_value.value')"-->
                <!--:type="'text'"-->
                <!--v-model="adminComment"-->
        <!--/>-->
      <!--</div>-->

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'requestFunds_Status',
            'requestFunds_cardStatusPaid',
            'requestFunds_New',
            'requestFunds_cardStatusUnapproved',
            ])"></div>
        <DefaultSelect
          :options="REQUEST_FUNDS_STATUSES_ARRAY"
          :label="$t('requestFunds_Status.localization_value.value')"
          :optionsLabel="'name'"
          :otherValue="'translation'"
          :selected="getSelectedStatus()"
          @change="changeStatus"
        />
      </div>


    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')"
                  class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {usersFunc} from "../../../../../../../mixins/usersMixins/usersFunc";
  import SearchSelect from "../../../../../../UI/selectiones/SearchSelect/SearchSelect";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {REQUEST_FUNDS_STATUSES_ARRAY} from "../../../../../../../staticData/staticVariables";
  import {
    ACCOUNT_BANK_PRIVAT, ACCOUNT_BANK_PAYONEER, ACCOUNT_BANK_WISE,
    ACCOUNT_BANK_PAYPAL, ACCOUNT_BANK_KIT_GROUP, ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT, ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT,
  } from "../../../../../../../staticData/staticVariables";

  export default {
    name: "RequestFundsFilter",
    components: {
      DefaultSelect,
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    computed: {
      getSelectedType() {
        return this.type !== '' ? this._.find(this.optionsBalanceType, {id: Number(this.type)}) : null
      },
    },

    data() {
      return {
        date: this.filterGetParams.date ? this.generateFilterDate(this.filterGetParams.date, 'MM/DD/YY') : '',
        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        total: this.filterGetParams.total ? this.filterGetParams.total : '',
        partial: this.filterGetParams.partial ? this.filterGetParams.partial : '',
        transaction: this.filterGetParams.transaction ? this.filterGetParams.transaction : '',
        account: this.filterGetParams.account ? this.filterGetParams.account : '',
        accountName: this.filterGetParams.accountName ? this.filterGetParams.accountName : '',
        comment: this.filterGetParams.comment ? this.filterGetParams.comment : '',
        adminComment: this.filterGetParams.adminComment ? this.filterGetParams.adminComment : '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        accountBank: this.filterGetParams.accountBank ? this.filterGetParams.accountBank : '',
        type: this.filterGetParams.type ? this.filterGetParams.type : '',

        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',

        REQUEST_FUNDS_STATUSES_ARRAY: REQUEST_FUNDS_STATUSES_ARRAY,

        options: [],
        optionsAccounts: [],
        optionsAccountBanks: [],
        optionsBalanceType: [],
        accoutBankSelected: null,

        filterCounts: [
          'id',
          'date',
          'total',
          'partial',
          'transaction',
          'account',
          'accountName',
          'comment',
          'adminComment',
          'status',
          'accountBank',
          'type',

          'userId',
          'userName',
        ],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.date = newVal.date ? this.generateFilterDate(newVal.date, 'MM/DD/YY') : ''

        this.total = newVal.total ? newVal.total : ''
        this.partial = newVal.partial ? newVal.partial : ''
        this.transaction = newVal.transaction ? newVal.transaction : ''
        this.account = newVal.account ? newVal.account : ''
        this.accountName = newVal.accountName ? newVal.accountName : ''
        this.comment = newVal.comment ? newVal.comment : ''
        this.adminComment = newVal.adminComment ? newVal.adminComment : ''
        this.status = newVal.status ? newVal.status : ''
        this.accountBank = newVal.accountBank ? newVal.accountBank : ''
      },

    },

    mounted() {
      this.$store.dispatch('getPayUserSystems').then(ressponse => {
        this.optionsBalanceType = this.getRespData(ressponse)
      })

      if (this.$route.query.status && this.isAdmin) {
        this.status = this.$route.query.status
      }
      if (this.isAdmin) {
        this.$store.dispatch('fetchAccountBanks', '?limit=100').then(result => {
          this.optionsAccountBanks = this.getRespPaginateData(result)

          let sortPriority = 7
          this.optionsAccountBanks.forEach(item => {
            if (item.id === ACCOUNT_BANK_PRIVAT.id) {
              item.sortPriority = 0
            }
            else if (item.id === ACCOUNT_BANK_PAYONEER.id) {
              item.sortPriority = 1
            }
            else if (item.id === ACCOUNT_BANK_WISE.id) {
              item.sortPriority = 2
            }
            else if (item.id === ACCOUNT_BANK_PAYPAL.id) {
              item.sortPriority = 3
            }
            else if (item.id === ACCOUNT_BANK_KIT_GROUP.id) {
              item.sortPriority = 4
            }
            else if (item.id === ACCOUNT_BANK_SKLADUSA_USER_ACCOUNT.id) {
              item.sortPriority = 5
            }
            else if (item.id === ACCOUNT_BANK_CUSTOM_BANK_ACCOUNT.id) {
              item.sortPriority = 6
            }
            else {
              item.sortPriority = sortPriority
              sortPriority++
            }
          })

          this.optionsAccountBanks.sort((a, b) => {
              if (a.sortPriority < b.sortPriority) {
                return -1
              }
              if (a.sortPriority > b.sortPriority) {
                return 1
              }

              return 0
          })

          if (this.$route.query.accountBank) {
            this.accoutBankSelected = this.optionsAccountBanks.find(item => {
              return item?.id === +this.$route.query?.accountBank
            })
          }
        })
      }
    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.date = this.changeDateParams(this, 'date')

        this.$emit('changeFilter', data)
      },

      changeAccountsFilter(val) {
        this.account = val.id
        this.accountName = val.bank.name
      },

      getFilteredAccounts(value, that, optionName, loading) {

        if(this.accountsFilterTimeoutTime !== undefined) {
          let dateNow = new Date()
          if(dateNow - this.accountsFilterTimeoutTime < 500){
            clearTimeout(this.accountsFilterTimeout)
            createTimeOut()
          }
        } else {
          createTimeOut()
        }

        function createTimeOut() {
          that.accountsFilterTimeoutTime = new Date()
          that.accountsFilterTimeout = setTimeout(() => {
            search()
          },500)
        }

        function search() {
          that.accountsFilterTimeoutTime = undefined

          if(value.length === 0) {
            that[optionName] = []
          }
          if(value.length < 1) {
            loading(false)
            return
          }

          const query = new that.Query();
          let url = '?';
          let myQuery = query
            .for('posts')

          myQuery.where('multipleFilter', value)

          url = url + myQuery.limit(100000).page(1).url().split('?')[1]

          that.$store.dispatch('fetchAccount', url).then(response => {
            that[optionName] = response.data.data.data
            loading(false)
            })
        }

      },

      getSelectedStatus() {
        return this.REQUEST_FUNDS_STATUSES_ARRAY.find(item => {
          return item.value === this.status
        })
      },

      changeStatus(val) {
        this.status = val?.value
      },

      changeBank(val) {
        this.accoutBankSelected = val
        this.accountBank = val?.id
      },

      changeType(val) {
        this.type = val?.id
      },
    },
  }
</script>

<style scoped>

</style>

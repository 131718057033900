<template>
  <div class="fragment">
    <div class="section-label mb-2"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_FundsDetails',])"></div>
      {{$t('requestFunds_FundsDetails.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col mb-2"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_AvailableSum',])"></div>
        <div class="soft-text">
          {{$t('requestFunds_AvailableSum.localization_value.value')}}

          <span v-if="isPayoneerBalance">
            ${{(RF.data.User.user.user_balances.length > 0 ? parseFloat(RF.data.User.user.user_balances[0].balance).toFixed(2) : 0)}}
          </span>
          <span v-if="isBaseBalance">${{RF.data.User.user.balance}}</span>.
        </div>
      </div>

      <div class="order-create__col custom-col">
        <div class="order-create__toggle-block toggle-block">
          <div class="toggle-block__type">
            <div class="toggle-block__type-list"
                 v-if="isBaseBalance">
              <div class="toggle-block__type-item mb-2"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['requestFunds_ByTransactions',])"></div>
                <RadioDefault
                        class="white-space-line"
                        :label="$t('requestFunds_ByTransactions.localization_value.value')"
                        :name="'insuranceRadio'"
                        :value="RF.data.withdrawalType === REQUEST_FUNDS_TRNS_TYPES.INDIVIDUAL"
                        @change.native="changeRadio(REQUEST_FUNDS_TRNS_TYPES.INDIVIDUAL)"
                />
              </div>
              <div class="toggle-block__type-item mb-2"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['requestFunds_BySum',])"></div>
                <RadioDefault
                        :label="$t('requestFunds_BySum.localization_value.value')"
                        :name="'insuranceRadio'"
                        :value="RF.data.withdrawalType === REQUEST_FUNDS_TRNS_TYPES.AUTOMATICALLY"
                        @change.native="changeRadio(REQUEST_FUNDS_TRNS_TYPES.AUTOMATICALLY)"
                />
              </div>
            </div>
          </div>
          <div class="toggle-block__details h-auto" style="min-height: initial;">

            <div class="d-flex custom-row"
                 v-if="RF.data.type.id === REQUEST_FUNDS_TYPES.BUSINESS.id">
              <div class="order-create__col custom-col custom-col--25 custom-col--md-50 custom-col--xs-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['requestFunds_NoVAT',])"></div>
                <RadioDefault
                        :label="$t('requestFunds_NoVAT.localization_value.value')"
                        :name="'VatRadio'"
                        @change.native="changeRadioVat(false)"
                        :value="!RF.data.vatCommission"
                />
              </div>
<!--              <div class="order-create__col custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-50 custom-col&#45;&#45;xs-100"-->
<!--                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--                <div class="admin-edit" @click="editTranslate(['requestFunds_VAT20',])"></div>-->
<!--                <RadioDefault-->
<!--                        :label="$t('requestFunds_VAT20.localization_value.value')"-->
<!--                        :name="'VatRadio'"-->
<!--                        :value="RF.data.vatCommission"-->
<!--                        @change.native="changeRadioVat(true)"-->
<!--                />-->
<!--              </div>-->
            </div>

            <div class="toggle-block__details-head">
              <div class="section-label mb-0"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['requestFunds_Details',])"></div>
                {{$t('requestFunds_Details.localization_value.value')}}
              </div>
            </div>
            <div class="toggle-block__details-body">
              <div class="custom-row">
                <div class="custom-col custom-col--25 custom-col--md-33 custom-col--sm-50"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['requestFunds_SelectSum',])"></div>
                  <InputSum
                          :label="$t('requestFunds_SelectSum.localization_value.value')"
                          :icoType="'dollar'"
                          :placeholder="'100'"
                          v-bind:class="{'ui-no-valid': RF.validation.transactions || RF.validation.selectSum}"
                          :errorTxt="$t(`${RF.validation.selectSum ? RF.validationTranslate.selectSum : RF.validationTranslate.transactions}.localization_value.value`)"
                          :error="RF.validation.transactions || RF.validation.selectSum"
                          :value="RF.data.selectSum"
                          :disabled="RF.data.statusLock"
                          @change="changeSelectSum"
                  />
<!--                          @focusOut="checkSelectSum"-->

                  <div class="position-relative mt-1 mb-2"
                       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                    <div class="admin-edit" @click="editTranslate(['requestFunds_VAT', 'requestFunds_toWithdRaw'])"></div>
                    <div class="soft-text font-weight-normal ws-nowrap position-absolute"
                         v-if="RF.data.vatCommission">
                      - 20% {{$t('requestFunds_VAT.localization_value.value')}} =
                      <span>${{parseFloat(parseFloat(RF.data.selectSum) * 0.2).toFixed(2)}}
                        {{$t('requestFunds_toWithdRaw.localization_value.value')}}
                      </span>
                    </div>
                  </div>
                </div>


<!--                <div class="custom-col fsz14"-->
<!--                     v-if="!isAdmin && isPayoneerBalance && RF.data.accountType && RF.data.accountType.id !== -1">-->
<!--                  Payoneer Commission - -->
<!--                  <b v-if="Number(RF.data.selectSum) > 0">-->
<!--                    ${{parseFloat(Number(RF.data.selectSum) * 0.025).toFixed(2)}}-->
<!--                  </b>-->
<!--                  <b v-else>$0</b>-->

<!--                  <div class="mt-2">-->
<!--                    Total - -->
<!--                    <b v-if="Number(RF.data.selectSum) > 0">-->
<!--                      ${{parseFloat(Number(RF.data.selectSum) * 0.025 + Number(RF.data.selectSum)).toFixed(2)}}-->
<!--                    </b>-->
<!--                    <b v-else>$0</b>-->
<!--                  </div>-->
<!--                </div>-->

                <div class="custom-col"
                     v-if="isBaseBalance"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['requestFunds_Transactions',])"></div>
                  <MultiSelect
                          :options="optionTransactions"
                          :label="$t('requestFunds_Transactions.localization_value.value')"
                          :otherValue="'requestFundsTransactions'"
                          v-bind:class="{'ui-no-valid': RF.validation.transactions}"
                          :errorTxt="$t(`${RF.validationTranslate.transactions}.localization_value.value`)"
                          :error="RF.validation.transactions"
                          :selected="RF.data.transactions"
                          :searchable="true"
                          :disabled="this.RF.getOldStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value"
                          @change="changeSelectedTransactions"
                  />
                </div>

                <div class="custom-col mb-0"
                     v-if="isBaseBalance">
                  <div class="sum-block-medium">
                    <div class="sum-block-medium__big">${{sumSelectedTransactions}}</div>
                    <div class="sum-block-medium__text"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['requestFunds_SumTransactions',])"></div>
                      {{$t('requestFunds_SumTransactions.localization_value.value')}}</div>
                  </div>

                </div>

                <div class="custom-col mb-0 mt-3"
                     v-if="isAdmin && this.RF.getOldStatus().value !== REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value && isBaseBalance"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                  <div class="admin-edit" @click="editTranslate(['requestFunds_CreateTransaction'])"></div>
                  <MainButton
                          class="order-create__footer-btn-i wfc"
                          :value="$t('requestFunds_CreateTransaction.localization_value.value')"
                          @click.native="createTransaction"
                  />

                </div>
              </div>
            </div>
          </div>
          <!--<div class="toggle-block__details h-auto" style="min-height: initial;"-->
               <!--v-if="RF.data.withdrawalType === REQUEST_FUNDS_TRNS_TYPES.AUTOMATICALLY"-->
          <!--&gt;-->
            <!--<div class="toggle-block__details-head">-->
              <!--<div class="section-label mb-0">-->
                <!--Details-->
              <!--</div>-->
            <!--</div>-->
            <!--<div class="toggle-block__details-body">-->
              <!--<div class="custom-row">-->
                <!--<div class="custom-col custom-col&#45;&#45;25 custom-col&#45;&#45;md-33 custom-col&#45;&#45;sm-50">-->
                  <!--<InputSum-->
                          <!--:label="'Select Sum'"-->
                          <!--:icoType="'dollar'"-->
                          <!--:placeholder="'100'"-->
                  <!--/>-->
                <!--</div>-->
                <!--<div class="custom-col mb-0">-->
                  <!--<MultiSelect-->
                          <!--:options="options"-->
                          <!--:selected="''"-->
                          <!--:label="'Transactions'"-->
                  <!--/>-->
                <!--</div>-->
              <!--</div>-->
            <!--</div>-->
          <!--</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  import MultiSelect from "../../../../../UI/selectiones/MultiSelect/MultiSelect";
  import RadioDefault from "../../../../../UI/radiobuttons/RadioDefault/RadioDefault";
  import {
    REQUEST_FUNDS_STATUSES,
    REQUEST_FUNDS_TRNS_TYPES,
    REQUEST_FUNDS_TYPES
  } from "../../../../../../staticData/staticVariables";
  import {requestFunds} from "../../../../../../mixins/requestFundsMixins/requestFunds";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import {NewPayments} from "../../../../NewPaymentsModule/NewPayments";
  import {newPaymentsMixin} from "../../../../../../mixins/newPaymentsMixins/newPaymentsMixin";
  export default {
    name: "FundsSumBlock",
    components: {MainButton, RadioDefault, MultiSelect, InputSum},

    mixins: [requestFunds, newPaymentsMixin],

    props: {
      RF: Object,
    },

    watch: {
      userId(){
        this.initTransactions()
      },

      isPayoneerBalance() {
        this.initPayoneerFundSum()
      },
    },

    computed: {
      balanceType() {
        return this.RF.data.balanceType
      },

      sumSelectedTransactions(){
        let sum = 0

        this.RF.data.transactions.map(item => {
          sum += parseFloat(item['user_amount'])
        })
        this.RF.setTransactionsSum(parseFloat(sum).toFixed(2))
        return parseFloat(sum).toFixed(2)
      }
    },

    data() {
      return {
        REQUEST_FUNDS_TYPES: REQUEST_FUNDS_TYPES,
        REQUEST_FUNDS_TRNS_TYPES: REQUEST_FUNDS_TRNS_TYPES,
        REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,

        optionTransactions: [],

        pm: new NewPayments(),
      }
    },

    mounted(){
      // getOutputPaymentList
      if(this.RF.data.User.getUserId()){
        this.initTransactions()
      }

      this.initPayoneerFundSum()
    },

    methods: {

      initPayoneerFundSum() {
        if(this.RF.data.balanceType.id === this.RF.data.balanceTypes.payoneerBalance.id) {
          if (this.RF.data.User.user.balance < 0) {
            this.RF.data.selectSum = parseFloat(this.RF.data.User.user.balance * -1).toFixed(2)
          }
        }
      },

      initTransactions(){
        this.$store.dispatch('getOutputPaymentList', {user_id: this.RF.data.User.getUserId()}).then(response => {
          this.optionTransactions = this.getRespData(response)
          if(this.RF.data.transactions.length > 0){
            this.RF.data.transactions.map(item => {
              if(!this._.find(this.RF.data.transactions, {id: parseInt(item.id)})) {
                this.optionTransactions.push(item)
              }
            })
          }
        })
      },

      createTransaction(){
        if(!this.RF.validationCreateTransaction()) return

        this.pm.setUser(this.RF.data.User.getUser())
        this.pm.setAmount(this.RF.getSelectSum())

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.createRemainedPayment(false, true, true).then((response) => {
            if(!response) return
            console.log(this.RF.data.transactions);
            this.optionTransactions.unshift(this.getRespData(response).transaction)
            this.RF.data.transactions.push(this.getRespData(response).transaction)
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }
        this.deleteFunc(text, confirm)
      },

      changeRadio(val){
        this.RF.setWithdrawalType(val)
      },

      changeRadioVat(val) {
        this.RF.setVatCommission(val)
      },

      changeSelectedTransactions(val) {
        this.RF.setTransactions(val)
      },

      checkSelectSum(val) {
        if(this.RF.data.balanceType.id === this.RF.data.balanceTypes.payoneerBalance.id) {
          if (this.RF.data.User.user.balance * -1 > Number(val)) {
            this.RF.data.selectSum = parseFloat(this.RF.data.User.user.balance * -1).toFixed(2)
          }
        }
      },

      changeSelectSum(val) {
        console.log(555);
        this.RF.setSelectSum(val)

        if(this.RF.getStatus().value === this.REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value)
          return

        if(this.RF.data.withdrawalType === REQUEST_FUNDS_TRNS_TYPES.AUTOMATICALLY){
          this.calculatedTransactionsBySum(val)
        }
      },

      calculatedTransactionsBySum(sumValue) {
        if(this.optionTransactions.length === 0) return

        console.log(11111);
        console.log(this.optionTransactions);
        console.log(sumValue);

        if(parseFloat(sumValue) < 1) return this.changeSelectedTransactions([])

        let sortTransactions = this._.sortBy(this.optionTransactions,
          function (item) {
            return parseFloat(item.user_amount)
          });

        let startSum = 0
        let lessIsEqualSumTransactions = []

        lessIsEqualSumTransactions = this.findLessIsEqualSum(sumValue, sortTransactions, lessIsEqualSumTransactions, startSum)

        this.changeSelectedTransactions(lessIsEqualSumTransactions)

      },


      /**
       *
       * This function is recursive
       * First step take greatest sum transaction
       * Next
       *
       * @param sumValue
       * @param transactionOptions
       * @param selectedTransactions
       * @param selectedSum
       * @returns {*}
       */
      findLessIsEqualSum(sumValue, transactionOptions, selectedTransactions = [], selectedSum = 0.00){
        let equalSum = false
        let selectTransaction = null
        let filteredTransactions = []
        let selectedNewSum = selectedSum
        let selectedNewTransactions = selectedTransactions

        /**
         * Filtering transaction where Transaction amount Less Is Equal searching sum
         */
        filteredTransactions = this._.filter(transactionOptions,
          function(transaction) {
            if(parseFloat(transaction['user_amount']) === parseFloat(sumValue)){
              equalSum = transaction
            }
            return parseFloat(transaction['user_amount']) <= parseFloat(sumValue)
          });

        /**
         * If first call this function and all transactions more searching sum
         * return the least transaction
         */
        if(selectedSum === 0 && filteredTransactions.length === 0){
          return [transactionOptions[0]]
        }

        /**
         * If first call this function and sum filtering transaction less searching sum
         * return the least transaction
         * OR if not find least transaction -> return all transaction
         */
        if(selectedSum === 0 && parseFloat(this._.sumBy(filteredTransactions, function (i) {return parseFloat(i['user_amount'])})) < sumValue){
          let leastTransaction = this._.find(transactionOptions, function(i) { return parseFloat(i['user_amount']) > sumValue })
          if(leastTransaction){
            return [leastTransaction]
          }
          return transactionOptions
        }

        /**
         * If dont first call this function and all transactions more searching sum
         * return the least transaction
         */
        if(selectedSum > 0 && filteredTransactions.length === 0){
          return selectedNewTransactions
        }

        /**
         * If first call this function and transaction equal searching sum
         */
        if(selectedSum === 0 && equalSum){
          return [equalSum]
        }

        /**
         * If first call this function
         * selectTransaction = greatest from filtered transactions
         */
        if(selectedSum === 0){
          selectTransaction = filteredTransactions[filteredTransactions.length - 1]
          /**
           * Add biggest transaction in selected transactions array, and remove this transaction from filtered array
           */
          selectedNewTransactions.push(selectTransaction)
          filteredTransactions.pop()
        }

        /**
         * If dont first call this function
         */
        if(selectedSum > 0){
          selectTransaction = filteredTransactions[0]
          /**
           * Add the least transaction in selected transactions array, and remove this transaction from filtered array
           */
          selectedNewTransactions.push(selectTransaction)
          filteredTransactions.shift()
        }

        /**
         * Calculation sum selected transactions
         */
        selectedNewSum = parseFloat(selectedSum) + parseFloat(selectTransaction['user_amount'])

        /**
         * Check if calculation sum selected transactions more then searching sum
         */
        if(parseFloat(sumValue) <= selectedNewSum) {
          return selectedNewTransactions
        }

        /**
         * Calculating remaining sum in filtered transactions
         */
        return this.findLessIsEqualSum(sumValue, filteredTransactions, selectedNewTransactions, selectedNewSum)

      }

    }

  }
</script>

<style scoped>

</style>

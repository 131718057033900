<template>
  <div class="fragment">
    <RequestFundsEditingSection
            :RF="RF"
            @save="save(true)"
    />
    <IbanPopup
        v-if="isIbanPopup"
        :accountBank="RF.data.accountType"
        @close="isIbanPopup = false"
    />
  </div>
</template>

<script>
  import RequestFundsEditingSection from "./RequestFundsEditingSection/RequestFundsEditingSection";
  import {RequestFunds} from "../../models/RequestFunds";
  import {requestFunds} from "../../../../../mixins/requestFundsMixins/requestFunds";
  import IbanPopup from "@/components/modules/RequestFundsModule/popups/IbanPopup/IbanPopup";

  export default {
    name: "RequestFundsEditing",
    components:{
      IbanPopup,
      RequestFundsEditingSection,
    },

    mixins: [requestFunds],

    watch: {
      loadUserAuthorizedData(){
        this.initializeRequestFundsEdit()
      },
    },

    data() {
      return {
        RF: new RequestFunds(),
        isIbanPopup: false,
      }
    },

    mounted() {
      this.RF.setType(this.$route?.meta?.breadcrumbs?.type)

      let id = this.$route.params.id
      this.RF.setId(id)

      if(this.loadUserAuthorizedData){
        this.initializeRequestFundsEdit()
      }

    },

    methods: {
      initializeRequestFundsEdit(){
        this.$store.dispatch('getEditRequestFunds', this.RF.getId()).then(response => {
          let dataItem = this.getRespData(response)

          this.RF.setItem(dataItem, this.isAdmin)
        }).catch(error => this.createErrorLog(error))
      },

    },

  }
</script>

<style scoped>

</style>

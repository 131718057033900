<template>

  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row">
        <DefaultCheckbox
                v-if="isAdmin && showRequestCheckbox(item)"
                class="mt-1"
                :selectedNow="selectedNow"
                :dataValue="item.id"/>

        <div class="table-card__item-number cursor-pointer"
             @click="$emit('showRequestFunds')"
        >
          #{{item.id}}
        </div>

      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY H:mm") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_ID.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              #{{item.id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_Date.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY H:mm") }}
            </div>
          </div>

          <div v-if="isAdmin" class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_balance.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.account"
                  :deep="'user.balance'"
              />
            </div>
          </div>
          <div v-if="isAdmin" class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_frozen.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.account"
                  :deep="'user.frozen_balance'"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_Total.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.payment_transaction_total_amount"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_Partial.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.output_amount"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('requestFunds_Commission.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.commission_amount"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_Transactions.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <div class="table-row" v-if="item.payment_transactions.length > 0">
                <div class="transactions-row"
                >
                    <span v-for="(itemTransactions, index) in item.payment_transactions"
                          :key="index"
                          class="site-link btn-style"
                          @click="$emit('showTransactionInfo', itemTransactions)"
                    >
<!--                          @click="showTransactionInfo(itemTransactions)">-->
                      #{{itemTransactions.id}}
                    </span>

                </div>
<!--                <v-popover-->
<!--                        class="site-tooltip"-->
<!--                        offset="5"-->
<!--                        placement="top-left"-->
<!--                        trigger="hover"-->
<!--                >-->
<!--                <span>-->
<!--                  <CountCircle-->
<!--                          :value="'+' + item.payment_transactions.length"-->
<!--                  />-->

<!--                </span>-->
<!--                  <template slot="popover">-->
<!--                    <p><b>Transactions</b></p>-->
<!--                    <p>-->
<!--                      <template v-for="(itemTransactions, index) in item.payment_transactions">-->
<!--                           <span class="table-link fsz11 btn-style tran-0 mr-1"-->
<!--                                 :key="index"-->
<!--                           >#{{itemTransactions.id}},</span>-->
<!--                      </template>-->
<!--                    </p>-->
<!--                  </template>-->
<!--                </v-popover>-->
              </div>
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_Account.localization_value.value')}}
            </div>
            <div class="table-card__item-info d-inline-block">
              <router-link class="table-link btn-style" :to="openAccountForEdit(item.account)">

                <template v-if="isIndividualBank(item.account)">
                  <template v-if="item.account['bank']['currentTranslate']['name']">
                    <ValueHelper
                        :value="item.account"
                        :deep="'bank.currentTranslate.name'"
                    /><template v-if="item.account.card_number || item.account.email || item.account.phone
                    || (item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name) ||
                    (item.account.bank_id === ACCOUNT_BANK_CRYPTO.id && item.account.crypto_name)">,
                    </template>
                  </template>

                  <template v-if="item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name">
                    <ValueHelper
                        :value="item.account.full_name"/>
                  </template>

                  <template v-if="item.account.bank_id === ACCOUNT_BANK_RAMP.id && item.account.middle_name">
                    <ValueHelper
                        :value="item.account.middle_name"/>,
                  </template>

                  <template v-if="item.account.card_number">
                    <ValueHelper
                        :value="item.account.card_number"
                    /><template v-if="item.account.email || item.account.phone">,
                    </template>
                  </template>

                  <template v-if="item.account.email">
                    <ValueHelper
                        :value="item.account.email"
                    /><template v-if="item.account.phone">,
                    </template>
                  </template>

                  <template v-if="item.account.phone">
                    <ValueHelper
                        :value="item.account.phone"
                    />
                  </template>

                  <template v-if="item.account.crypto_name">
                    <ValueHelper
                        class="inline-block"
                        :value="item.account.crypto_name"
                    /><template v-if="item.account.crypto_wallet">,
                    </template>
                  </template>
                  <template v-if="item.account.crypto_wallet">
                    <ValueHelper
                        :value="item.account.crypto_wallet"
                    />
                  </template>
                </template>

                <template v-if="isBusinessBank(item.account)">
                  <template v-if="item.account.full_name">
                    <ValueHelper
                        :value="item.account.full_name"
                    /><template v-if="item.account.iban">,
                    </template>
                  </template>
                  <template v-if="item.account.iban">
                    <ValueHelper
                        :value="item.account.iban"/>
                    <template v-if="CURRENCIES.UAH.id === item.account.currency_id">&nbsp;UAH</template>
                    <template v-if="CURRENCIES.USD.id === item.account.currency_id">&nbsp;USD</template>
                  </template>
                </template>
              </router-link>
<!--              <ValueHelper-->
<!--                      :value="item.account"-->
<!--                      :deep="'full_name'"-->
<!--              />-->
            </div>
          </div>
          <div v-if="isAdmin" class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_accountBank.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                  :value="item.account"
                  :deep="'bank.currentTranslate.name'"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_YourComment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.user_comment"
              />
            </div>
          </div>
          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_AdminComment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <ValueHelper
                      :value="item.admin_comment"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('requestFunds_Status.localization_value.value')}}
            </div>
            <div class="table-card__item-info">

              <template v-if="item.status === REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value ||
                      item.status === REQUEST_FUNDS_STATUSES.WAIT_CONFIRM_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'in-transit'" class="mr-2"/>
                {{$t('requestFunds_InProcess.localization_value.value')}}
              </template>
              <template v-if="item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'star'" class="mr-2"/>
                {{$t('requestFunds_New.localization_value.value')}}
              </template>

              <template v-if="item.status === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'delivered'" class="mr-2"/>
                {{$t('requestFunds_Paid.localization_value.value')}}
              </template>

              <template v-if="item.status === REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'rejected'" class="mr-2"/>
                <template v-if="canRequestATM">
                  <TooltipTextHelper
                          :text="$t('requestFunds_Rejected.localization_value.value')"
                          :paragraph="item.rejected_reason"
                  />
                </template>
                <template v-else>{{ $t('requestFunds_Rejected.localization_value.value') }}</template>
              </template>

              <template v-if="item.status === REQUEST_FUNDS_STATUSES.ERROR_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'cancel-red'" class="mr-2"/>
                {{$t('requestFunds_Error.localization_value.value')}}
              </template>
              <template v-if="item.status === REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value">
                <StatusIcoBtn :type="'declined'" class="mr-2"/>
                {{$t('requestFunds_Unapproved.localization_value.value')}}
              </template>
            </div>
          </div>
        </div>

          <div class="table-card__item-btn">
            <div class="table-card__item-btn-i"
                 v-if="isAdmin">
              <LinkButton
                      :value="$t('common_edit.localization_value.value')"
                      :type="'edit'"
                      :link="linkEdit"
              />
            </div>

            <div class="table-card__item-btn-i"
                 v-if="(isAdmin && item.status !== REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value) ||
                 (isAdmin && item.status !== REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value) ||
                 (!isAdmin && item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value)"
            >
              <LinkButton
                  :value="$t('common_cancellation.localization_value.value')"
                  :type="'delete'"
                  @click.native="$emit('cancelItem')"
              />
            </div>

            <div class="table-card__item-btn-i"
                 v-if="getUserRequestATM && canRequestATM(item) && !isAdmin && canShowManageBtns(item)">
              <LinkButton
                  :value="$t('requestFunds_ATM.localization_value.value')"
                  :type="'atm'"
                  @click.native="$emit('sendRequestFunds', item.id)"
                  :disabled="$store.getters.requestFundsBtn"
              />
            </div>

            <div class="table-card__item-btn-i"
                 v-if="item.balance_output_invoice === null">
              <LinkButton
                  :value="$t('invoices_createInvoice.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('createInvoiceFunds', item.id)"
              />
            </div>

            <div class="table-card__item-btn-i"
                 v-if="item.balance_output_invoice !== null">
              <LinkButton
                  :value="$t('invoices_editInvoice.localization_value.value')"
                  :type="'dog'"
                  @click.native="$emit('editInvoiceFunds', item.id, item.balance_output_invoice.id)"
              />
            </div>

          </div>

      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  // import CountCircle from "../../../../../../../coreComponents/CountCircle/CountCircle";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import ValueHelper from "../../../../../../../coreComponents/ValueHelper/ValueHelper";
  import {
    ACCOUNT_BANK_RAMP,
    ACCOUNT_BANK_TYPE, CURRENCIES,
    REQUEST_FUNDS_STATUSES
  } from "../../../../../../../../staticData/staticVariables";
  import {requestFundsTable} from "../../../../../../../../mixins/requestFundsMixins/requestFunds";
  import {accountsHelperMixin} from "../../../../../../../../mixins/account/accountMixin";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {ACCOUNT_BANK_WISE} from "../../../../../../../../staticData/staticVariables";
  import {ACCOUNT_BANK_CRYPTO} from "../../../../../../../../staticData/staticVariables";

  export default {
    name: "RequestFundsTableMobile",
    components: {
      TooltipTextHelper, ValueHelper, LinkButton, StatusIcoBtn,
      // CountCircle,
      DefaultCheckbox},

    mixins: [requestFundsTable, accountsHelperMixin],

    props: {
      item: Object,
      selectedNow: Boolean,
      linkEdit: String,
    },

    methods: {
      openAccountForEdit(val){

        if (!val?.bank) {
          return '#'
        }

        let data = {
          route: this.$store.getters.GET_PATHS.accounts,
          itemId: val.id,
          bankTypeId: val["bank"]["type_id"],
        }
        let bankType = this._.find(ACCOUNT_BANK_TYPE , ['id', data.bankTypeId]);
        return `${data.route}/${bankType.name}/${data.itemId}`
      },
    },

    data() {
      return {
        show: false,
        REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,
        ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
        ACCOUNT_BANK_CRYPTO: ACCOUNT_BANK_CRYPTO,
        ACCOUNT_BANK_RAMP: ACCOUNT_BANK_RAMP,
        CURRENCIES: CURRENCIES,
      }
    }

  }
</script>

<style scoped>

</style>

<template>

  <div class="fragment mt-2">
    <RequestFundsTableUser
        :countFilterParams="countFilterParams"
        :filterGetParams="filterGetParams"
        :sortParams="sortParams"
        @changeSort="data => changeSort(data)"
        @resetFilter="resetFilter"
        @showMore="showMore"
        @changeFilter="changeFilter"
        @reload="reload"
        @sendRequestFunds="id => sendRequestFunds(id, true, true)"
        @sendRequestFundsNoAPI="sendRequestFundsNoAPI"
        @multipleRequestFunds="multipleRequestFunds"
        @downloadFiles="downloadFiles"
    />

    <MultipleRequestFundsPopup
        :items="itemsRequest"
        v-if="multipleRequestFundsPopup"
        @close="multipleRequestFundsPopup = false"
    />
  </div>
</template>

<script>
import RequestFundsTableUser from "./RequestFundsTableUser/RequestFundsTableUser";
import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
import MultipleRequestFundsPopup from "../../popups/MultipleRequestFundsPopup/MultipleRequestFundsPopup";
import {requestFundsTable} from "../../../../../mixins/requestFundsMixins/requestFunds";
import {REQUEST_FUNDS_STATUSES, WITHOUT_API_TYPE} from "../../../../../staticData/staticVariables";

export default {
  name: "RequestFundsTable",
  components: {
    MultipleRequestFundsPopup,
    RequestFundsTableUser,
  },

  mixins: [routeFilter, requestFundsTable],

  data() {
    return {
      forPage: this.$store.getters.getRequestFundsForPage,
      page: 1,
      onePage: 1,

      filterId: '',
      filterUserId: '',
      filterUserName: '',
      filterDate: [],
      filterTotal: '',
      filterPartial: '',
      filterTransaction: '',
      filterAccount: '',
      filterAccountName: '',
      filterAccountBank: '',
      filterComment: '',
      filterAdminComment: '',
      filterStatus: '',
      filterType: '',

      sortParams: {
        sortId: '',
        sortCreatedAt: '',
        sortStatus: '',
        sortUser: '',
        sortOutputAmount: '',
        sortUserBalance: '',
      },

      countFilterParams: 0,
      filterGetParams: {},

      itemsRequest: null,
      multipleRequestFundsPopup: false
    }
  },

  mounted() {
    // if(!this.$route.query.sortId) {
    //   let query = Object.assign({}, this.$route.query)
    //   query.sortId = '-'
    //   this.$router.replace({ query })
    // }
    this.filter()
    this.checkSingleOutputInDataUrl()
  },

  methods: {

    checkSingleOutputInDataUrl() {
      if (this.$route.query?.requestId) {
        if (this.$route.query?.requestType !== 'default') {
          this.sendRequestFunds(parseInt(this.$route.query?.requestId))
          this.$router.push({path: '', query: {}})
        } else {
          this.sendRequestFundsNoAPI(parseInt(this.$route.query?.requestId))
        }
      }
    },

    getFilterParam() {
      this.filterGetParams = {
        page: this.$route.query.page,
        count: this.$route.query.count,

        id: this.$route.query.id,
        userId: this.$route.query.userId,
        userName: this.$route.query.userName,
        date: this.$route.query.date,
        total: this.$route.query.total,
        partial: this.$route.query.partial,
        transaction: this.$route.query.transaction,
        account: this.$route.query.account,
        accountName: this.$route.query.accountName,
        accountBank: this.$route.query.accountBank,
        comment: this.$route.query.comment,
        adminComment: this.$route.query.adminComment,
        status: this.$route.query.status,
        type: this.$route.query.type,
      }
    },

    getSortParam() {
      this.sortParams = {
        sortId: this.$route.query.sortId || '',
        sortCreatedAt: this.$route.query.sortCreatedAt || '',
        sortStatus: this.$route.query.sortStatus || '',
        sortUser: this.$route.query.sortUser || '',
        sortOutputAmount: this.$route.query.sortOutputAmount || '',
        sortUserBalance: this.$route.query.sortUserBalance || '',
      }
    },

    filter(next = false) {
      this.getFilterParam()
      this.getSortParam()

      let url

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(next, this)

      // generate other filter if has get params in URL
      this.generateParamsFilterFromUrl(this)

      // Generate filter URL
      url = this.generateFilterUrl(
          generateMainFilter.page,
          generateMainFilter.forPage
      )


      /** Getting actual totals**/
      this.$store.dispatch('getBalanceOutputTotals', url)

      if (next) this.$store.commit('setNextRequestFundsPage', true)
      this.$store.dispatch('fetchRequestFunds', url).then(() => {
        this.$store.commit('setNextRequestFundsPage', false)
        this.$store.commit('setGlobalUpdateTable', false)

        if (this.$route.query.tableRequestIds) {
          let requestIds = this.$route.query.tableRequestIds.split(',')
          let typeId = this.$route.query.typeId

          this.multipleRequestFunds({id: parseInt(typeId)}, requestIds)

          let query = Object.assign({}, this.$route.query);
          delete query.tableRequestIds;
          delete query.typeId;
          this.$router.replace({query});
        }
      })


      this.checkCountFilter(['userName', 'accountName'])
    },

    generateFilterUrl(page, forPage) {
      let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

      this.generateFilterQueryParams(
          myQuery,
          {

            filterUserId: 'BalanceOutputUserId',
            filterId: 'BalanceOutputId',
            filterTotal: 'BalanceOutputTransactionTotalAmount',
            filterPartial: 'BalanceOutputUserAmount',
            filterTransaction: 'PaymentTransactionsId',
            filterAccount: 'BalanceOutputAccountId',
            filterAccountBank: 'BalanceOutputBankId',
            filterComment: 'ointRecipientFullName',
            filterAdminComment: 'ointRecipientPhone',
            filterStatus: 'BalanceOutputStatus',
            filterType: 'OutputBalanceType',

          },
      )

      this.generateSortQueryParams(
          myQuery,
          {
            sortId: 'BalanceOutputId',
            sortCreatedAt: 'BalanceOutputCreatedAt',
            sortStatus: 'BalanceOutputStatus',
            sortUser: 'BalanceOutputUserFullName',
            sortOutputAmount: 'BalanceOutputOutputAmount',
            sortUserBalance: 'BalanceOutputUserBalance',
          }
      )

      if (this.filterDate.length > 0) {
        let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

        myQuery.whereIn(
            'between_created_at',
            date
        )
      }
      return this.generateGetParamsFromQuery(myQuery, forPage, page)
    },


    multipleRequestFunds(val, ids = []) {
      let checkboxIds

      if (ids.length > 0) {
        checkboxIds = ids
      } else {
        checkboxIds = this.getCheckedRows('row-name')
      }

      checkboxIds = Array.from(checkboxIds, el => parseInt(el))

      if (val.id === WITHOUT_API_TYPE.id) {
        this.requestWithoutAPI(checkboxIds)
        return
      }

      let items = []
      checkboxIds.map((id) => {
        let item = this._.find(this.$store.getters.getRequestFunds, {id: parseInt(id)})

        if (item?.account?.bank?.id === val.id) {
          items.push(item)
        }
      })


      if (items.length === 0) {
        this.openNotify('error', 'common_notificationPleaseChooseItemRequest')
        return
      }

      // if (val.id === ACCOUNT_BANK_WISE.id) {
      //   this.requestWiseBank(val.id, checkboxIds)
      //   return
      // }

      let count = this.$route.query.count || this.forPage
      let page = this.$route.query.page || this.page
      this.ATMAccess(`tableRequestIds=${checkboxIds.join(',')}&typeId=${val.id}&count=${count}&page=${page}`).then(checkAccess => {
        if (checkAccess) {
          this.itemsRequest = items
          this.multipleRequestFundsPopup = true
        }
      })


    },

    async requestWiseBank(bankId, ids) {
      let access = true
      for (let id of ids) {
        await this.setRequestFunds(id).then(async () => {
          await this.ATMAccess(`tableRequestIds=${ids.join(',')}&typeId=${bankId}`).then(async checkAccess => {
            access = checkAccess
            if (checkAccess) {
              await this.setStatusRequestAndSave(REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS)
            }
          })
        })
      }

      if (!access) return

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({path: '', query: {}})
      }

      this.reload()
      // this.openNotify('success', 'common_notificationStatusChanged')
    },

    async requestWithoutAPI(checkboxIds) {
      let ids = []
      let lostItems = []
      checkboxIds.map((id) => {
        let item = this._.find(this.$store.getters.getRequestFunds, {id: parseInt(id)})

        if (item?.account?.apiOutput === false) {
          ids.push(id)
        } else {
          lostItems.push(id)
        }
      })

      if (ids.length === 0) {
        this.openNotify('error', 'common_notificationPleaseChooseItemRequest')
        return
      }

      let access = true
      for (let id of ids) {
        await this.setRequestFunds(id).then(async () => {
          await this.ATMAccess(`tableRequestIds=${ids.join(',')}&typeId=${WITHOUT_API_TYPE.id}`).then(async checkAccess => {
            access = checkAccess
            if (checkAccess) {
              await this.setStatusRequestAndSave(REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS)
            }
          })
        })
      }

      if (!access) return

      if(lostItems.length > 0) {
        this.openNotify('error', {txtServer: this.$t('common_notificationLostRequests.localization_value.value') + ' <b>' + lostItems.join(', ') + '</b>'}, 10000)
      }

      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push({path: '', query: {}})
      }

      this.reload()
      // this.openNotify('success', 'common_notificationStatusChanged')
    },

    downloadFiles(type) {
      let forPage, page, url, exportIds

      exportIds = this.getCheckedRows('row-name')

      // generate main filter if has get params in URL
      let generateMainFilter = this.generateMainFilterFromUrl(false, this)

      page = generateMainFilter.page
      forPage = generateMainFilter.forPage

      url = this.generateFilterUrl(page, forPage, exportIds)

      this.$store.dispatch('getExportRequestFunds', {filter: url, exportType: type}).then((response) => {
        if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
          const blob = new Blob([response.data], {type: `application/${type}`})
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Request Funds.${type}`
          link.click()
          URL.revokeObjectURL(link.href)
        } else {
          setTimeout(() => {
            this.openNotify('error', 'common_notificationUndefinedError')
          }, 200)
        }
      }).catch(error => this.createErrorLog(error))

    },

  },

  destroyed() {
    setTimeout(() => {
      this.$store.commit('setRequestFunds', {requestFunds: []})
      this.$store.commit('setRequestFundsCommonList', {requestFundsCommonList: []})
    }, 300)
  },

}
</script>

<style scoped>

</style>

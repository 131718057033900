import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {Order} from "@/components/globalModels/Order";
import {User} from "@/components/globalModels/User";
import {SimpleProduct} from "@/components/globalModels/SimpleProduct";
import {InvoiceSeller} from "@/components/globalModels/InvoiceSeller";

export function RequestFundsInvoice() {

  this.checkValid = checkValid

  this.data = {
    Order: new Order(),
    User: new User(),
    InvoiceSeller: new InvoiceSeller(),
    simpleProducts: [new SimpleProduct()],
  }

  this.validation = {
    // fopName: false,
    // bankName: false,
    // bankSwift: false,
    // bankAccount: false,
  }

  this.validationTranslate = {
    // fopName: '',
    // bankName: '',
    // bankSwift: '',
    // bankAccount: '',
  }

  this.validationTxt = {
    // fopName: false,
    // bankName: false,
    // bankSwift: false,
    // bankAccount: false,
  }

}


/*****************   SETTERS   ******************/
// RequestFundsInvoice.prototype.setFopName = function (val) {
//   this.data.fopName = val;
// }
//
// RequestFundsInvoice.prototype.setBankName = function (val) {
//   this.data.bankName = val;
// }
//
// RequestFundsInvoice.prototype.setBankSwift = function (val) {
//   this.data.bankSwift = val;
// }
//
// RequestFundsInvoice.prototype.setBankAccount = function (val) {
//   this.data.bankAccount = val;
// }

RequestFundsInvoice.prototype.addProduct = function () {
  this.data.simpleProducts.push(new SimpleProduct())
  return this.data.simpleProducts.length - 1
}

RequestFundsInvoice.prototype.removeProduct = function (index) {
  this.data.simpleProducts.splice(index, 1)
  return this.data.simpleProducts.length - 1
}

RequestFundsInvoice.prototype.setItem = function (val) {

  this.data.Order.setId(val.id)
  this.data.InvoiceSeller.setItem(val.seller)

  this.data.simpleProducts = []
  val.products.map(item => {
    let index = this.addProduct()
    this.data.simpleProducts[index].setItem(item)
    this.data.simpleProducts[index].setName(item.item_name)
  })

}


/*****************   GETTERS   ******************/
// RequestFundsInvoice.prototype.getFopName = function () {
//   return this.data.fopName;
// }
//
// RequestFundsInvoice.prototype.getBankName = function () {
//   return this.data.bankName;
// }
//
// RequestFundsInvoice.prototype.getBankSwift = function () {
//   return this.data.bankSwift;
// }
//
// RequestFundsInvoice.prototype.getBankAccount = function () {
//   return this.data.bankAccount;
// }


/*****************   VALIDATION   ******************/

RequestFundsInvoice.prototype.itemValidate = function (isAdmin = false) {

  // let validationItems = {
  //   fopName: this.data.fopName,
  //   bankName: this.data.bankName,
  //   bankSwift: this.data.bankSwift,
  //   bankAccount: this.data.bankAccount,
  // }
  //
  // let validationOptions = {
  //   fopName: {type: ['empty']},
  //   bankName: {type: ['empty']},
  //   bankSwift: {type: ['empty']},
  //   bankAccount: {type: ['empty']},
  // }

  let valid = true

  if(!this.data.InvoiceSeller.itemValidate()) {
    valid = false
  }
  this.data.simpleProducts.map(item => {
    item.dataValidation({name: true, count: true, price: true})
  })

  if(isAdmin) {
    valid = this.data.User.userValidation({userId: true})
  }

  return valid
}

RequestFundsInvoice.prototype.prepareData = function () {
  let data = {
    "balance_output_id": this.data.Order.getOrderOBJ()?.balance_output_id,
    "seller" : {
      "fop_name": this.data.InvoiceSeller.getFopName(),
      "bank_name": this.data.InvoiceSeller.getBankName(),
      "bank_swift": this.data.InvoiceSeller.getBankSwift(),
      "bank_account": this.data.InvoiceSeller.getBankAccount()
    },
    "products": []
  }

  let products = this.data.simpleProducts.map(item => {
    return {
      "item_name": item.getName(),
      "quantity": item.getCount(),
      "price": item.getPrice()
    }
  })


  data['products'] = products

  return data
}

import {RequestFunds} from "../../components/modules/RequestFundsModule/models/RequestFunds";
import {
  ACCOUNT_BANK_BENTO_VISA_CARD,
  ACCOUNT_BANK_PAYONEER, ACCOUNT_BANK_PRIVAT, ACCOUNT_BANK_RAMP,
  REQUEST_FUNDS_STATUSES
} from "../../staticData/staticVariables";
import {socketMixin} from "../socketMixins/socketMixin";
import {checkAccess} from "../userCredentionalMixins/userCredentional";


export const requestFundsSocket = {
  mixins: [socketMixin, checkAccess],

  methods: {

    destroyRequestFundsSocket(){
      if(this.$store.getters.getRequestFundsSocket){
        let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestFunds')
        console.log('close requestFundsSocket');
        window.Echo.leave(`${prepareSocket.channel}.${prepareSocket.user}`)
        this.$store.commit('setRequestFundsSocket', false)
      }
    },

    prepareRequestFundsSocket(single){
      /**
       * Create Socket Channel
       * @type {*|{channel, active, event, user}|{active}}
       */
      let prepareSocket = this.prepareCreatePrivateSocketChannel('RequestFunds')

      if (prepareSocket.active) {
        this.createRequestFundsSocket(
          prepareSocket,
          (response) => {
            console.log(response);
            console.log('socket status', response.data?.balance_output?.status);


            let id = response.data?.balance_output?.id
            let item = this._.find(this.$store.getters.getRequestFunds, {id: id})
            let responseStatus = response.data?.balance_output?.status
            let responseRejectedReason = response.data?.balance_output?.rejected_reason

            /**
             * Change table item data
             */
            item.status = responseStatus
            item.rejected_reason = responseRejectedReason

            /**
             * If API Error
             */
            if (response.data.status !== true) {
              this.notifyErrorHelper(response.data?.error)
              this.requestFundsSocketItem = response.data
              return
            }

            /**
             * If single Fund
             */
            if(single){

              /**
               * Notify for socket status
               */
              switch (responseStatus) {
                case REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value:
                  this.openNotify('success', 'common_notificationRecordChanged')
                  console.log('success or reject - chanel closed');
                  this.destroyRequestFundsSocket()
                  break

                case REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value:
                  this.openNotify('error', 'common_notificationUndefinedError')
                  console.log('success or reject - chanel closed');
                  this.destroyRequestFundsSocket()
                  break

                case REQUEST_FUNDS_STATUSES.ERROR_OUTPUT_STATUS.value:
                  this.openNotify('error', 'common_notificationUndefinedError')
                  console.log('success or reject - chanel closed');
                  this.destroyRequestFundsSocket()
                  break

                case REQUEST_FUNDS_STATUSES.WAIT_CONFIRM_OUTPUT_STATUS.value:
                  break
              }

              // if(responseStatus === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value ||
              //   responseStatus === REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value){
              //
              //   console.log('success or reject - chanel closed');
              //   this.destroyRequestFundsSocket()
              // }

            } else {
              console.log('mass socket item status');
              console.log(response.data);
              this.requestFundsSocketItem = response.data
            }

          }
        )
      }
    },

    createRequestFundsSocket(prepareSocket, functionEvent){
      this.$store.commit('setRequestFundsSocket', true)

      window.Echo.private(`${prepareSocket.channel}.${prepareSocket.user}`)
        .listen(`.${prepareSocket.event}`, (response) => {
          functionEvent(response)
        }).error(e => {
        console.log(e);
      })
    },

    openRequestFundsSocket(single) {
      this.prepareRequestFundsSocket(single)
    },
  }

}



export const requestFunds = {

  computed:{
    userId() {
      return this.RF.data.User.userId
    },

    isBaseBalance() {
      return this.RF.data.balanceType.id === this.RF.data.balanceTypes.baseBalance.id
    },

    isPayoneerBalance() {
      return this.RF.data.balanceType.id === this.RF.data.balanceTypes.payoneerBalance.id
    },
  },

  methods: {

    canRequestATM(item){
      return item?.account?.bank_id === ACCOUNT_BANK_PAYONEER.id ||
        item?.account?.bank_id === ACCOUNT_BANK_BENTO_VISA_CARD.id ||
        item?.account?.bank_id === ACCOUNT_BANK_RAMP.id ||
        item?.account?.bank_id === ACCOUNT_BANK_PRIVAT.id
    },

    save(edit = false, table = false, isATMButton = false) {

      if(this.isPayoneerBalance) {

        if(!this.RF.validationDataByPayoneer()) return

      } else if(this.RF.getStatus().value !== REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value){

        if(!this.RF.validationData()) return
        if(!this.RF.validationTransactionSum()) return

      }

      let data = this.RF.prepareSave(this.isAdmin)

      if(this.RF.getOldStatus().value !== REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value &&
        this.RF.getStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value && !table){
        data['status'] = 'new'
      }

      if(isATMButton) {
        data['is_atm'] = isATMButton
      }

      // if(this.RF.getStatus() === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS){
      //   data['status'] = REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS
      // }

      let saveType = 'createRequestFunds'

      if(edit){
        saveType = 'updateRequestFunds'
        if(this.RF.getOldStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value){
          saveType = 'updateAdjustRequestFunds'
        }
        data = {
          id: this.RF.getId(),
          data: data
        }
      }


      return this.$store.dispatch(saveType, data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            console.log(this.getRespData(response));
            if(!edit) this.RF.setId(this.getRespData(response)?.balance_output?.id)

            if(this.isAdmin &&
              this.RF.getStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value &&
              !this.RF.getStatusLock()){
              if(this.canRequestATM({account: this.RF.getAccountType()})){
                this.$router.push(this.$store.getters.GET_PATHS.financeRequestFunds + '?requestId=' + this.RF.getId() + '&requestType=ATM')
              } else {
                this.$router.push(this.$store.getters.GET_PATHS.financeRequestFunds + '?requestId=' + this.RF.getId() + '&requestType=default')
              }
              return response
            }

            if(!table){
              this.$router.push(this.$store.getters.GET_PATHS.financeRequestFunds)
            }

            if(!this.canRequestATM({account: this.RF.getAccountType()})){
              this.openNotify('success', 'common_notificationRecordCreated')
            }
            return response
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {

            if(response.response.data.special_form) {
              this.openIbanPopup()
            }

            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    openIbanPopup() {
      this.isIbanPopup = true
    },

  }
}

export const requestFundsTable = {
  mixins: [requestFunds, requestFundsSocket],

  data() {
    return {
      RF: new RequestFunds()
    }
  },

  methods: {

    showRequestCheckbox(item) {
      return item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value
    },

    canShowManageBtns(item) {

      switch (this.isAdmin) {
        case false:
          return item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value

        case true:
          return item.status !== REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value &&
            item.status !== REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value

        default:
          return false
      }
    },

    sendRequestFundsNoAPI(id){
      this.sendRequestFunds(id, false)
    },

    sendRequestFunds(id, withAPI = true, isATMButton = false){
      this.setRequestFunds(id).then(() => {
        this.$router.push({ path: '', query: {}})
        this.ATMAccess(`requestId=${id}&requestType=${withAPI ? 'api' : 'default'}`).then(checkAccess => {
          if(checkAccess) {
            this.setStatusRequestAndSave(REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS, isATMButton).then((response) => {

             if(this.getResponseStatus(response) === this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE) {
               return
             }

              if(withAPI){
                this.sendRequestFundsFunc(id)
              } else {
                this.$router.push({ path: '', query: {}})
                this.reload()
              }
            })
          }
        })
      })
    },

    requestFundsCancel(id){
      this.setRequestFunds(id).then(() => {
        this.setStatusRequestAndSave(REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS).then(() => {
          setTimeout(() => {
            this.$emit('reload')
          })
        })
      })
    },

    setRequestFunds(id){
      return this.$store.dispatch('getRequestFunds', id).then(response => {
        let dataItem = this.getRespData(response)
        this.RF.setId(id)
        this.RF.setItem(dataItem, this.isAdmin)
      }).catch(error => this.createErrorLog(error))
    },

    setStatusRequestAndSave(status = false, isATMButton = false){
      if(!this.isAdmin && status === REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS) {
        this.RF.setComment('User canceled')
      }

      /**
       * if REQUEST FUNDS STATUSES equal SUCCESS OUTPUT STATUS or IN PROCESS OUTPUT STATUS
       */
      if(this.RF.getStatus().value === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value ||
        this.RF.getStatus().value === REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value){
        return new Promise((resolve) => {resolve(true)})
      }

      this.RF.setStatus(status)

      return this.save(true, true, isATMButton).then(response => {
        return response
      })
    },


    sendRequestFundsFunc(id){
      let data = {
        'balance_output_id': id,
      }

      this.$store.dispatch('singleRequestFundsOutput', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            // this.openNotify('success', 'common_notificationStatusChanged')

            setTimeout(() => {
              let item = this._.find(this.$store.getters.getRequestFunds, {id: id})

              item.status = REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value
            },100)

            this.openRequestFundsSocket(true, [id])
            // this.reload()

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
            /**
             * Undefined Error
             */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

    sendMassRequestFunds(){

      let ids = []

      this.items.map(item => {
        ids.push(item.id)
      })

      let data = {
        'balance_output_ids': ids
      }

      this.$store.dispatch('massRequestFundsOutput', data).then(response => {
        if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            // this.openNotify('success', 'common_notificationStatusChanged')

            ids.map(id => {
              let item = this._.find(this.$store.getters.getRequestFunds, {id: id})

              item.status = REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value
            })


            this.openRequestFundsSocket(false, ids)

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            this.isUpload = false
            this.openNotify('error', 'common_notificationUndefinedError')
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.isUpload = false
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }
      })
    },

  },
}








<template>
  <div class="order-create__product custom-row"
       @mouseover="isHovering2 = true"
       @mouseout="isHovering2 = false"
       :class="{hover: isHovering2}"
  >
    <div class="order-create__product-col custom-col  custom-col--md-100 position-relative d-flex">
      <div class="admin-edit" @click="editTranslate(['common_InvoiceProduct'])"></div>
      <div class="order-create__product-delete" v-if="canRemoveProduct">
        <div @click="$emit('removeProduct')" class="cursor-pointer">
          <DeleteProductBtn/>
        </div>
      </div>

      <div class="custom-row">

        <div class="order-create__col custom-col">
          <DefaultCheckbox
              v-model="fromProfile"
              :label="$t('common_fromProfiles.localization_value.value')"
              @click="fromProfile = !fromProfile"
          />
        </div>

        <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
             v-if="fromProfile"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_InvoiceProduct',])"></div>
          <DefaultSelect
              class="w-100"
              :options="optionsProduct"
              :label="$t('common_InvoiceProduct.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.name}"
              :error="item.validation.selectUser"
              :errorTxt="$t(`${item.validationTranslate.name}.localization_value.value`)"
              :optionsLabel="optionsLabel"
              :selected="item.data.productObject"
              @change="changeSimpleProduct"
          />
<!--              :functionSearch="functionSearchPackaging"-->
        </div>

        <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
             v-else
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
<!--          <div class="admin-edit" @click="editTranslate(['common_InvoiceProduct',])"></div>-->
          <DefaultInput
              class="w-100"
              :label="$t('importO_productName.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.name}"
              :error="item.validation.selectUser"
              :errorTxt="$t(`${item.validationTranslate.name}.localization_value.value`)"
              v-model="item.data.name"
          />
<!--              :functionSearch="functionSearchPackaging"-->
        </div>

        <div class="order-create__col custom-col custom-col--33 mb-0 custom-col--md-100"></div>

        <!--    <div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
        <!--         v-if="nameCyrillic"-->
        <!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--      <div class="admin-edit" @click="editTranslate([-->
        <!--                        'product_ProductNameCyrillic',-->
        <!--                      ])"></div>-->
        <!--      <DefaultInput-->
        <!--          :label="$t('product_ProductNameCyrillic.localization_value.value')"-->
        <!--          :type="'text'"-->
        <!--          v-bind:class="{'ui-no-valid': simpleProduct.validation.nameCyrillic}"-->
        <!--          :errorTxt="$t(`${simpleProduct.validationTranslate.nameCyrillic}.localization_value.value`)"-->
        <!--          :error="simpleProduct.validation.nameCyrillic"-->
        <!--          v-model="simpleProduct.data.nameCyrillic"-->
        <!--      />-->
        <!--    </div>-->

        <!--    <div class="order-create__col custom-col custom-col&#45;&#45;33 custom-col&#45;&#45;md-100"-->
        <!--         v-if="hSCode"-->
        <!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
        <!--      <div class="admin-edit" @click="editTranslate([-->
        <!--                        'proform_hSCodeCode',-->
        <!--                      ])"></div>-->
        <!--      <DefaultInput-->
        <!--          :label="$t('proform_hSCodeCode.localization_value.value')"-->
        <!--          :type="'text'"-->
        <!--          v-bind:class="{'ui-no-valid': simpleProduct.validation.hSCode}"-->
        <!--          :errorTxt="$t(`${simpleProduct.validationTranslate.hSCode}.localization_value.value`)"-->
        <!--          :error="simpleProduct.validation.hSCode"-->
        <!--          v-model="simpleProduct.data.hSCode"-->
        <!--      />-->
        <!--    </div>-->

        <!--    <div v-if="hSCode" class="order-create__col custom-col custom-col&#45;&#45;33 mb-0 custom-col&#45;&#45;md-100"></div>-->


        <div class="order-create__col custom-col custom-col--25 custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'common_ItemsQty',
                        ])"></div>

          <InputQuantity
              :label="$t('common_ItemsQty.localization_value.value')"
              v-bind:class="{'ui-no-valid': item.validation.count}"
              :errorTxt="$t(`${item.validationTranslate.count}.localization_value.value`)"
              :error="item.validation.count"
              v-model="item.data.count"
              @change="changeValueCount"
          />
        </div>

        <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'common_PricePerItem',
                        ])"></div>
          <InputSum
              :label="$t('common_PricePerItem.localization_value.value')"
              :icoType="'dollar'"
              :numeric="true"
              :placeholder="'0'"
              v-bind:class="{'ui-no-valid': item.validation.price}"
              :errorTxt="$t(`${item.validationTranslate.price}.localization_value.value`)"
              :error="item.validation.price"
              v-model="item.data.price"
              @input="changeValueSum"

          />
        </div>

        <div class="order-create__col custom-col custom-col--33 custom-col--md-25 custom-col--sm-50 position-relative"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
                          'common_totalSum',
                        ])"></div>

          <InputSum
              :label="$t('common_totalSum.localization_value.value')"
              :icoType="'dollar'"
              v-model="item.data.totalSum"
              :disabled="true"
          />


          <div class="order-create__product-add-to-profile"
               v-if="!_.has(item.data.productObject, 'id') && !isAdmin"
               @click="addToProfile">



            <template>
              <v-popover
                  class="site-tooltip"
                  offset="5"
                  placement="top"
                  trigger="hover"
              >
                      <span>
                        <AddToProfileIco/>
                      </span>
                <template slot="popover">
                  {{$t('common_AddProfile.localization_value.value')}}
                </template>
              </v-popover>
            </template>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import InputQuantity from "@/components/UI/inputs/InputQuantity/InputQuantity";
import InputSum from "@/components/UI/inputs/InputSum/InputSum";
import AddToProfileIco from '../../../../../../../public/img/UI-group/add-to-profile-ico.svg?inline'
import DeleteProductBtn from "@/components/coreComponents/Buttons/DeleteProductBtn/DeleteProductBtn";
// import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";
import DefaultCheckbox from "@/components/UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import DefaultSelect from "@/components/UI/selectiones/DefaultSelect/DefaultSelect";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

export default {
  name: "SimpleProductItem",
  components: {
    DefaultInput,
    DefaultSelect,
    DefaultCheckbox,
    // SearchSelect,
    DeleteProductBtn,
    InputSum,
    InputQuantity,
    AddToProfileIco,
  },

  props: {
    item: Object,
    optionsProduct: Array,
    canRemoveProduct: Boolean,
    optionsLabel: String,
  },

  data() {
    return {
      isHovering2: false,
      fromProfile: false,
    }
  },

  methods: {
    changeSimpleProduct(val) {
      this.$emit('changeSimpleProduct', val)
    },

    changeValueSum(val) {
      this.$emit('changeValueSum', val)
    },

    changeValueCount(val) {
      this.$emit('changeValueCount', val)
    },

    addToProfile() {
      this.$emit('addToProfile')
    },

    functionSearchPackaging(val, loading) {
      loading(true)

      if (this.proformSearchTimeoutTime !== undefined) {
        let dateNow = new Date()
        if (dateNow - this.proformSearchTimeoutTime < 500) {
          clearTimeout(this.proformSearchTimeout)
        }
      }

      this.createTimeOut(val, loading)
    },

    createTimeOut(val, loading) {
      this.proformSearchTimeoutTime = new Date()
      this.proformSearchTimeout = setTimeout(() => {
        this.$emit('getProducts', val, loading, this.item)
      }, 500)
    },



  }

}

</script>

<style scoped>
.order-create__product-delete {
  width: 22px;
}
</style>
<template>
  <div class="fragment">
<!--    v-if="RF.data.type.id === REQUEST_FUNDS_TYPES.INDIVIDUAL.id"-->
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_ChooseTypeBalance',])"></div>
      {{ $t('requestFunds_ChooseTypeBalance.localization_value.value') }}
    </div>

    <div class="custom-row" v-if="RF.data.balanceType.id === RF.data.balanceTypes.payoneerBalance.id">
      <div class="custom-col mb-3" style="margin-top: -10px;">
        <b>{{ $t('requestFunds_PayoneerCommission.localization_value.value') }}</b>
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100">
        <div class="d-flex align-items-center">
          <div class="mr-3"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([RF.data.balanceTypes.baseBalance.name,])"></div>
            <RadioDefault
                class="white-space-line"
                :label="$t(`${RF.data.balanceTypes.baseBalance.name}.localization_value.value`)"
                :name="'accountType'"
                :value="RF.data.balanceType.id === RF.data.balanceTypes.baseBalance.id"
                @change.native="RF.data.balanceType = RF.data.balanceTypes.baseBalance"
            />
          </div>
          <div
              v-if="isUserHasPayoneerBalance"
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([RF.data.balanceTypes.payoneerBalance.name,])"></div>
            <RadioDefault
                :label="$t(`${RF.data.balanceTypes.payoneerBalance.name}.localization_value.value`)"
                :name="'accountType'"
                :value="RF.data.balanceType.id === RF.data.balanceTypes.payoneerBalance.id"
                @change.native="RF.data.balanceType = RF.data.balanceTypes.payoneerBalance"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
import {REQUEST_FUNDS_TYPES} from "@/staticData/staticVariables";
import {requestFunds} from "@/mixins/requestFundsMixins/requestFunds";

export default {
  name: "BalanceType",

  components: {RadioDefault},

  mixins: [requestFunds],

  props: {
    RF: Object,
  },

  watch: {
    userId(){
      this.checkUserBalances()
    },
  },

  data() {
    return {
      REQUEST_FUNDS_TYPES: REQUEST_FUNDS_TYPES,
      isUserHasPayoneerBalance: false,
    }
  },

  methods: {
    checkUserBalances() {
      if(this.RF.data.User?.user?.user_balances && this._.find(this.RF.data.User.user.user_balances, {pay_system_type_id: 1})) {
        this.isUserHasPayoneerBalance = true
      }
    },
  }


}
</script>

<style scoped>

</style>
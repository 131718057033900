import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {User} from "../../../globalModels/User";
import {REQUEST_FUNDS_STATUSES, REQUEST_FUNDS_TRNS_TYPES} from "../../../../staticData/staticVariables";
import _ from 'lodash'

export function RequestFunds() {

  this.checkValid = checkValid

  this.data = {
    id: '',
    createdAt: '',
    type: '',
    User: new User(),
    accountType: '',
    withdrawalType: REQUEST_FUNDS_TRNS_TYPES.INDIVIDUAL,
    vatCommission: false,
    transactions: [],
    selectSum: 0,
    transactionsSum: 0,
    comment: '',
    userComment: '',

    ignoreAmountLimit: false,
    commissionAmount: 0,
    systemCommissionAmount: 0,
    totalCommissionAmount: 0,
    status: REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS,
    oldStatus: REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS,

    historyOfChanged: null,

    balanceTypes: {
      baseBalance: {
        id: 0,
        name: 'requestFunds_BaseBalance'
      },
      payoneerBalance: {
        id: 1,
        name: 'requestFunds_PayoneerBalance'
      }
    },
    balanceType: {
      id: 0,
      name: 'requestFunds_BaseBalance'
    },

    /**
     * If the status is PAID for editing,
     * further editing of the status is impossible
     */
    statusLock: false
  }


  this.validation = {
    accountType: false,
    transactions: false,
    selectSum: false,
    comment: false,
  }

  this.validationTranslate = {
    accountType: '',
    transactions: '',
    selectSum: '',
    comment: '',
  }

  this.validationTxt = {
    accountType: false,
    transactions: false,
    selectSum: false,
    comment: false,
  }

  /**
   * Getters
   */
  this.getId = () => {
    return this.data.id
  }
  this.getCreatedAt = () => {
    return this.data.createdAt
  }
  this.getType = () => {
    return this.data.type
  }
  this.getAccountType = () => {
    return this.data.accountType
  }
  this.getWithdrawalType = () => {
    return this.data.withdrawalType
  }
  this.getVatCommission = () => {
    return this.data.vatCommission
  }
  this.getTransactions = () => {
    return this.data.transactions
  }
  this.getSelectSum = () => {
    return this.data.selectSum
  }
  this.getTransactionsSum = () => {
    return this.data.transactionsSum
  }
  this.getComment = () => {
    return this.data.comment
  }
  this.getUserComment = () => {
    return this.data.userComment
  }
  this.getStatus = () => {
    return this.data.status
  }
  this.getOldStatus = () => {
    return this.data.oldStatus
  }
  this.getIgnoreAmountLimit = () => {
    return this.data.ignoreAmountLimit
  }
  this.getCommissionAmount = () => {
    return this.data.commissionAmount
  }
  this.getSystemCommissionAmount = () => {
    return this.data.systemCommissionAmount
  }
  this.getTotalCommissionAmount = () => {
    return this.data.totalCommissionAmount
  }
  this.getStatusLock = () => {
    return this.data.statusLock
  }


  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setCreatedAt = (val) => {
    this.data.createdAt = val
  }
  this.setType = (val) => {
    this.data.type = val
  }
  this.setAccountType = (val) => {
    this.data.accountType = val
  }
  this.setWithdrawalType = (val) => {
    this.data.withdrawalType = val
  }
  this.setVatCommission = (val) => {
    this.data.vatCommission = val
  }
  this.setTransactions = (val) => {
    this.data.transactions = val
  }
  this.setSelectSum = (val) => {
    this.data.selectSum = val
  }
  this.setTransactionsSum = (val) => {
    this.data.transactionsSum = val
  }
  this.setComment = (val) => {
    this.data.comment = val
  }
  this.setUserComment = (val) => {
    this.data.userComment = val
  }
  this.setStatus = (val) => {
    this.data.status = val
  }
  this.setOldStatus = (val) => {
    this.data.oldStatus = val
  }
  this.setIgnoreAmountLimit = (val) => {
    this.data.ignoreAmountLimit = val
  }
  this.setCommissionAmount = (val) => {
    this.data.commissionAmount = val
  }
  this.setSystemCommissionAmount = (val) => {
    this.data.systemCommissionAmount = val
  }
  this.setTotalCommissionAmount = (val) => {
    this.data.totalCommissionAmount = val
  }
  this.setStatusLock = (val) => {
    this.data.statusLock = val
  }
  this.setHistoryOfChanged = (val) => {
    this.data.historyOfChanged = val
  }

}

/**
 * Global Setters
 */

RequestFunds.prototype.setItem = function(item, isAdmin = false) {

  this.data.User.setUser(item['account']['user'])

  this.setCreatedAt(item['created_at'])

  // this.setWithdrawalType(_.find(REQUEST_FUNDS_TRNS_TYPES, {value: item?.selecting_transaction_mode}))
  this.setAccountType(item?.account ? item.account : '')
  this.setSelectSum(item?.output_amount)
  this.setTransactions(item?.payment_transactions)
  this.setTransactionsSum(item?.payment_transaction_total_amount)
  this.setStatus(_.find(REQUEST_FUNDS_STATUSES, {value: item?.status}))
  this.setOldStatus(_.find(REQUEST_FUNDS_STATUSES, {value: item?.status}))
  this.setVatCommission(item.vat_percent !== null)

  /**
   * If the status is PAID for editing,
   * further editing of the status is impossible
   * statusLock = true
   */
  if(_.find(REQUEST_FUNDS_STATUSES, {value: item?.status}).value ===
    REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value){
    this.setStatusLock(true)
  }

  if(item.balance_type_id === 1) {
    this.data.balanceType = this.data.balanceTypes.payoneerBalance
  }

  if(isAdmin){
    this.setUserComment(item?.user_comment ? item.user_comment : '')
    this.setComment(item?.admin_comment ? item.admin_comment : '')
    this.setIgnoreAmountLimit(item?.ignore_amount_limit ? true : false)
    this.setCommissionAmount(item?.commission_amount ? item.commission_amount : '')
    this.setSystemCommissionAmount(item?.system_commission_amount ? item.system_commission_amount : '')
    this.setHistoryOfChanged(item.activityUUID)
  } else {
    this.setComment(item?.user_comment ? item.user_comment : '')
  }

  this.setTotalCommissionAmount(item?.totalCommissionAmount ? item.totalCommissionAmount : '')
}




/**
 * Validations
 */

RequestFunds.prototype.validationDataByPayoneer = function(){

  let validationItems = {
    accountType: this.getAccountType(),
    selectSum: this.getSelectSum(),
  }

  let validationOptions = {
    accountType: {type: ['empty']},
    selectSum: {type: ['not-zero']},
  }

  return this.checkValid(validationItems, validationOptions)
}


RequestFunds.prototype.validationData = function(){

  let validationItems = {
    accountType: this.getAccountType(),
    transactions: this.getTransactions(),
  }

  let validationOptions = {
    accountType: {type: ['empty']},
    transactions: {type: ['empty-array']},
  }

  // if(this.getWithdrawalType().id === REQUEST_FUNDS_TRNS_TYPES.AUTOMATICALLY.id){
    validationItems.selectSum = this.getSelectSum()
    validationOptions.selectSum = {type: ['not-zero']}

  // Id 3 = Payoneer
  if(this.data.accountType?.bank?.id === 3 && Number(this.getSelectSum()) < 25) {
    this.validation.selectSum = true
    this.validationTranslate.selectSum = 'common_moreTwentyFive'
    return false
  }

  // }
  // else {
  //   validationItems.transactionsSum = this.getTransactionsSum()
  //   validationOptions.transactionsSum = {type: ['not-zero']}
  // }

  return this.checkValid(validationItems, validationOptions)
}

RequestFunds.prototype.validationTransactionSum = function(){
  if(parseFloat(this.getSelectSum()) > parseFloat(this.getTransactionsSum())){
    this.validation.transactions = true
    this.validationTranslate.transactions = 'error_notValidTransactionSum'
    return false
  }

  return true
}

RequestFunds.prototype.validationAdminFields = function(){

  let validationItems = {
    status: this.getStatus(),
  }

  let validationOptions = {
    status: {type: ['empty']},
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.User.userValidation({userId: true}))
}

RequestFunds.prototype.validationCreateTransaction = function(){

  let validationItems = {
    selectSum: this.getSelectSum(),
  }

  let validationOptions = {
    selectSum: {type: ['not-zero']},
  }

  return (this.checkValid(validationItems, validationOptions) && this.data.User.userValidation({userId: true}))
}



/**
 * Prepare Data
 */

RequestFunds.prototype.prepareSave = function(isAdmin) {

  let data = {
    "account_id": this.getAccountType()?.id === -1 ? null : this.getAccountType()?.id,
    "selecting_transaction_mode": this.getWithdrawalType().value,
    "output_amount": parseFloat(this.getSelectSum()),
    "use_vat": this.getVatCommission(),
    "vat_percent": this.getVatCommission() ? 20 : null,
    "payment_transaction_ids": [],
    "status": this.getStatus().value,
    "balance_type_id": this.data.balanceType.id
  }

  this.getTransactions().map(item => {
    data['payment_transaction_ids'].push(parseInt(item?.id))
  })

  if(isAdmin){
    data['ignore_amount_limit'] = this.getIgnoreAmountLimit() ? 1 : 0
    data['commission_amount'] = parseFloat(this.getCommissionAmount())
    data['system_commission_amount'] = parseFloat(this.getSystemCommissionAmount())
    data['admin_comment'] = this.getComment()
    data['user_comment'] = this.getUserComment()
  } else {
    data['user_comment'] = this.getComment()
  }

  return data
}

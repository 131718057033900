<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_Commission',])"></div>
      {{$t('requestFunds_Commission.localization_value.value')}}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_Commission',])"></div>
        <DefaultInput
                :label="$t('requestFunds_Commission.localization_value.value')"
                v-bind:class="{'ui-no-valid': RF.validation.commissionAmount}"
                :errorTxt="$t(`${RF.validationTranslate.commissionAmount}.localization_value.value`)"
                :error="RF.validation.commissionAmount"
                v-model="RF.data.commissionAmount"
        />
      </div>
    </div>


    <div class="custom-row" v-if="RF.data.balanceType.id === RF.data.balanceTypes.payoneerBalance.id">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_CommissionSystem',])"></div>
        <DefaultInput
                :label="$t('requestFunds_CommissionSystem.localization_value.value')"
                v-model="RF.data.systemCommissionAmount"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
  import {REQUEST_FUNDS_STATUSES_ARRAY} from "../../../../../../staticData/staticVariables";

  export default {
    name: "AdminCommission",
    components: {DefaultInput},

    props: {
      RF: Object,
    },

    data() {
      return {
        options: REQUEST_FUNDS_STATUSES_ARRAY
      }
    },

    methods: {
      changeStatus(val) {
        this.RF.setStatus(val)
      }
    }

  }
</script>

<style scoped>

</style>

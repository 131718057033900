<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      {{accountBank.bank.name}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'requestFunds_PayoneerBalance',
          'payments_NewPayment',
          'common_Amount',
          'payments_comment',
        ])"></span>

        <div class="custom-row">
          <div class="order-create__col custom-col"
               v-bind:class="{'admin-edit-item': isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['account_iBan'])"></div>
            <DefaultInput
                :label="$t('account_iBan.localization_value.value')"
                :placeholder="'_ _  _ _  _ _ _ _ _ _  _ _ _ _ _  _ _ _ _  _ _ _ _   _ _  _ _ _ _'"
                v-bind:class="{'ui-no-valid': account.validation.iban}"
                :error="account.validation.iban"
                :errorTxt="$t(`${account.validationTranslate.iban}.localization_value.value`)"
                v-mask="'AA###########################'"
                v-model="account.data.iban"
            />
          </div>
        </div>

      </div>
    </template>

    <template slot="footer">
      <div class="d-flex align-items-center flex-wrap">
        <MainButton
            :value="$t('common_edit.localization_value.value')"
            @click.native="saveIban(accountBank.id)"
        />
      </div>
    </template>

  </modal>
</template>

<script>
import Modal from '../../../../commonModals/Modal.vue'
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {Account} from "@/components/modules/AccountsModule/models/Account";
import {accountMixin} from "@/mixins/account/accountMixin";
import MainButton from "@/components/UI/buttons/MainButton/MainButton";

export default {
  name: "IbanPopup",
  components: {
    MainButton,
    DefaultInput,
    Modal,
  },

  mixins: [accountMixin],

  props: {
    accountBank: Object,
  },

  data() {
    return {
      account: new Account(),
    }
  },

  mounted() {
    let accountId =  this.accountBank.id
    this.$store.dispatch('getAccount', accountId ).then((response) => {
      let item = this.getRespData(response)
      this.account.setItem(item)
    }).catch(error => this.createErrorLog(error))

    this.account.setFieldsValid({
      firstName: true,
      lastName: true,
      // phone: true,
      typeSelected: true,
      iban: true,
      ibanUa: true,
    })

  }
}

</script>

<style lang="scss">

</style>

<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_Comment',])"></div>
      {{$t('requestFunds_Comment.localization_value.value')}}
    </div>
    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_Comment',])"></div>
        <TextareaDefault
                :label="$t('requestFunds_Comment.localization_value.value')"
                v-bind:class="{'ui-no-valid': RF.validation.comment}"
                :errorTxt="$t(`${RF.validationTranslate.comment}.localization_value.value`)"
                :error="RF.validation.comment"
                v-model="RF.data.comment"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  export default {
    name: "Comment",
    components: {TextareaDefault},

    props: {
      RF: Object,
    },

    data() {
      return {

      }
    }
  }
</script>

<style scoped>

</style>

<template>
  <div class="detail-page">
    <div class="detail-page__left">
<!--         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--      <div class="admin-edit" @click="editTranslate(['breadcrumbs_NewFundRequestIndividual',])"></div>-->
      <CardLeftBlock
              :backgroundImage="'product-admin'"
      >
        <template slot="button">
          <div class="card-detail-left__date">
<!--            <h2 class="mt-0">{{$t('breadcrumbs_NewFundRequestIndividual.localization_value.value')}}</h2>-->
            {{ new Date() | moment("DD MMM, YYYY") }}
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.financeRequestFunds" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-brown"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_RequestFunds',])"></div>
              {{$t('requestFunds_RequestFunds.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_PleaseFillOut',])"></div>
              {{$t('requestFunds_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row" v-if="$store.getters.getIsAdminRights === 'admin'">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="RF.data.User.validation.userId"
                        :validationTranslateUser="RF.data.User.validationTranslate.userId"
                        :userSelected="RF.data.User.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
            </div>

            <BalanceType
                :RF="RF"
            />

            <ChooseAccount
                    :RF="RF"
            />

            <FundsSumBlock
                    :RF="RF"
            />

            <AdminCommission
                    v-if="isAdmin"
                    :RF="RF"
            />

            <Comment
                    :RF="RF"
            />

            <AdminFields
                    v-if="isAdmin"
                    :RF="RF"
            />
          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeRequestFunds"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_Request'])"></div>
              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('requestFunds_Request.localization_value.value')"
                      @click.native="$emit('save')"
                      v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import ChooseAccount from "../../chunks/ChooseAccount/ChooseAccount";
  import FundsSumBlock from "../../chunks/FundsSumBlock/FundsSumBlock";
  import Comment from "../../chunks/Comment/Comment";
  import AdminFields from "../../chunks/AdminFields/AdminFields";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import AdminCommission from "../../chunks/AdminFields/AdminCommission";
  import BalanceType from "@/components/modules/RequestFundsModule/components/chunks/BalanceType/BalanceType";


  export default {
    name: "RequestFundsIndividualUser",

    components: {
      BalanceType,
      AdminCommission,
      UserSelect,
      AdminFields,
      Comment,
      FundsSumBlock,
      ChooseAccount,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      // InputSum,
      // RadioDefault,
      // TextareaDefault,
      // MultiSelect,
      // DefaultSelect,
    },

    props: {
      RF: Object,
    },

    data() {
      return {
        lotNumber: '',
        transaction: '#5496823: $1.57',
        valueSum: '',

        options: [
          '#49357: $100.4',
          '#49357: $10.4',
          '#49357: $59.2'
        ],

        optionsSelected: 'sdafdas',

        options2: [{}],

        radioVal: 0,

        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed in blandit nisl. Integer eu fermentum neque. Phasellus justo risus, placerat sed tortor non, tristique lacinia elit. Aenean mattis finibus augue sed gravida. Quisque et varius nisl. Nunc mauris dui, ullamcorper eget nunc nec, consequat bibendum nunc'
      }
    },

    methods: {

      changeUser(user) {
        this.RF.data.User.setUser(user)
      },

      changeValueSum(data) {
        this.valueSum = data
      },

      changeImg(files) {
        this.files = files
      },

      changeRadio(val) {
        this.radioVal = val
      }
    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

</style>

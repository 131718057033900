<template>
  <div class="detail-page">
    <div class="detail-page__left"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_FundRequestID',])"></div>
      <CardLeftBlock
              :name="$t('requestFunds_FundRequestID.localization_value.value')"
              :value="'#' + RF.getId()"
              :backgroundImage="'product-admin'"
      >
        <template slot="button">
          <div class="card-detail-left__date">
            {{ RF.data.createdAt | moment("DD MMM, YYYY") }}
          </div>
          <div class="site-link brown text-decoration-underline mt-4">
            <div v-if="isAdmin && RF.data.historyOfChanged"
                 class="mb-3"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
              <div class="site-link text-decoration-underline btn-style brown"
                   @click="historyOfChangesFunc('requestFunds', RF.data.historyOfChanged)">
                {{$t('common_CheckHistory.localization_value.value')}}
              </div>
            </div>
          </div>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['ex_backTo',])"></div>
                <router-link :to="$store.getters.GET_PATHS.financeRequestFunds" class="order-create__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                          @click.native="goRouterBack()"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title color-brown"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_RequestFunds',])"></div>
              {{$t('requestFunds_RequestFunds.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-0"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_PleaseFillOut',])"></div>
              {{$t('requestFunds_PleaseFillOut.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <div class="order-create__section">

            <div class="custom-row" v-if="isAdmin">
              <div class="custom-col custom-col--33 custom-col--md-100">
                <UserSelect
                        :validationUser="RF.data.User.validation.userId"
                        :validationTranslateUser="RF.data.User.validationTranslate.userId"
                        :userSelected="RF.data.User.user"
                        :typeSelect="'users'"
                        :userEmail="true"
                        :disabled="true"
                />
              </div>
            </div>

            <BalanceType
                :RF="RF"
            />

            <ChooseAccount
                    :RF="RF"
            />

            <FundsSumBlock
                    :RF="RF"
            />

            <AdminCommission
                    v-if="isAdmin"
                    :RF="RF"
            />

            <Comment
                    :RF="RF"
            />

            <AdminFields
                    v-if="isAdmin"
                    :RF="RF"
            />

          </div>

        </template>
        <template slot="footer">
          <div class="order-create__footer">

            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeRequestFunds"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>
            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate(['requestFunds_Request'])"></div>
              <MainButton
                      class="order-create__footer-btn-i"
                      :value="$t('requestFunds_Request.localization_value.value')"
                      @click.native="$emit('save')"
                      v-bind:class="{'disabled-btn' : (RF.getStatusLock() && !isAdmin) || $store.getters.getRequestFundsBtn}"
              />
            </div>

          </div>
        </template>
      </CardRightBlock>

    </div>
  </div>
</template>

<script>
  import CardLeftBlock from "../../../../../../components/coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import MainButton from "../../../../../../components/UI/buttons/MainButton/MainButton";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack.vue";
  import ChooseAccount from "../../chunks/ChooseAccount/ChooseAccount";
  import FundsSumBlock from "../../chunks/FundsSumBlock/FundsSumBlock";
  import Comment from "../../chunks/Comment/Comment";
  import AdminFields from "../../chunks/AdminFields/AdminFields";
  import AdminCommission from "../../chunks/AdminFields/AdminCommission";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import BalanceType from "@/components/modules/RequestFundsModule/components/chunks/BalanceType/BalanceType";


  export default {
    name: "RequestFundsEditingSection",

    components: {
      BalanceType,
      UserSelect,
      AdminCommission,
      AdminFields,
      Comment,
      FundsSumBlock,
      ChooseAccount,
      CardLeftBlock,
      MainButton,
      CardRightBlock,
      LinkBack,
      // InputSum,
      // RadioDefault,
      // TextareaDefault,
      // MultiSelect,
      // DefaultSelect,
    },

    props: {
      RF: Object,
    },

    data() {
      return {

      }
    },

    methods: {

    }
  }
</script>

<style lang="scss">

  .hide-upc {
    opacity: 0.5;
    pointer-events: none;
  }

  .product-progress-steps {
    max-width: 625px;
  }

</style>

<template>
  <div class="page-content">
    <Breadcrumb/>
    <SearchEngine
        v-if="isAdmin"
    />

    <router-view></router-view>

  </div>
</template>

<script>
  import Breadcrumb from "../../templates/Breadcrumb/Breadcrumb.vue";
  import SearchEngine from "../../coreComponents/SearchEngine/SearchEngine";

  export default {
    name: "RequestFundsModule",

    components:{
      SearchEngine,
      Breadcrumb,
    },

    data(){
      return {

      }
    },

  }
</script>

<style scoped>

</style>

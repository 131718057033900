<template>
  <modal
          @close="$emit('close')"
          class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{item.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'requestFunds_PayoneerBalance',
          'payments_NewPayment',
          'common_Amount',
          'payments_comment',
        ])"></span>

        <div class="transaction-info__name">
          <div class="table-row mb-3" v-if="item.balance_type_id === 1">
            <img src="/img/dashboard-group/payoneer-logo.png" alt="img" style="max-width: 20px; margin-right: 5px;">
            {{$t('requestFunds_PayoneerBalance.localization_value.value')}}
          </div>
          <div class="table-row">
            <template v-if="item.status === REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value ||
                      item.status === REQUEST_FUNDS_STATUSES.WAIT_CONFIRM_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'in-transit'" class="mr-2"/>
              {{$t('requestFunds_InProcess.localization_value.value')}}
            </template>
            <template v-if="item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'star'" class="mr-2"/>
              {{$t('requestFunds_New.localization_value.value')}}
            </template>
            <template v-if="item.status === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'delivered'" class="mr-2"/>
              <template v-if="item.atm === 0">{{ $t('requestFunds_Paid.localization_value.value') }}</template>
              <template v-if="item.atm === 1">{{ $t('requestFunds_PaidATM.localization_value.value') }}</template>
            </template>

            <template v-if="item.status === REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'rejected'" class="mr-2"/>
              <template v-if="canRequestATM">
                <TooltipTextHelper
                        :text="$t('requestFunds_Rejected.localization_value.value')"
                        :paragraph="item.rejected_reason"
                />
              </template>
              <template v-else>{{ $t('requestFunds_Rejected.localization_value.value') }}</template>
            </template>

            <template v-if="item.status === REQUEST_FUNDS_STATUSES.ERROR_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'cancel-red'" class="mr-2"/>
              {{$t('requestFunds_Error.localization_value.value')}}
            </template>
            <template v-if="item.status === REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value">
              <StatusIcoBtn :type="'declined'" class="mr-2"/>
              {{$t('requestFunds_Unapproved.localization_value.value')}}
            </template>
          </div>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__row">
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Date.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div v-if="isAdmin" class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('common_user.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <TableUserNameRow
                        class="line-height-normal"
                        v-if="item.account"
                        :item="item.account"
                        :label="false"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Commission.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.total_commission_amount"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50" v-if="item.paid_at">
              <div class="transaction-info__label">
                {{$t('requestFunds_PaidAt.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.paid_at"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Total.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">

                <ValueHelper
                        :value="item.payment_transaction_total_amount"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Partial.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.output_amount"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Transactions.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-for="(itemTransactions, index) in item.payment_transactions">
                           <span class="tran-0 mr-1 table-link btn-style float-left mt-1"
                                 @click="$emit('openInfo', itemTransactions)"
                                 :key="index"
                           >#{{itemTransactions.id}}<span
                                   v-if="index < item.payment_transactions.length - 1">,</span></span>
                </template>
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_Account.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <template v-if="item.account.bank_id === 2">
                  {{item.account['bank'].name}}
                </template>

                <template v-if="isIndividualBank(item.account)">
                  <template v-if="item.account['bank']['currentTranslate']['name']">
                    <ValueHelper
                        :value="item.account"
                        :deep="'bank.currentTranslate.name'"
                    /><template v-if="item.account.card_number || item.account.email || item.account.phone
                    || (item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name) ||
                    (item.account.bank_id === ACCOUNT_BANK_CRYPTO.id && item.account.crypto_name)">,
                    </template>
                  </template>

                  <template v-if="item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name">
                    <ValueHelper
                        :value="item.account.full_name"/>
                  </template>

                  <template v-if="item.account.card_number">
                    <ValueHelper
                        :value="item.account.card_number"
                    /><template v-if="item.account.email || item.account.phone">,
                    </template>
                  </template>

                  <template v-if="item.account.email">
                    <ValueHelper
                        :value="item.account.email"
                    /><template v-if="item.account.phone">,
                    </template>
                  </template>

                  <template v-if="item.account.phone">
                    <ValueHelper
                        :value="item.account.phone"
                    />
                  </template>
                  <template v-if="item.account.crypto_name">
                    <ValueHelper
                        :value="item.account.crypto_name"
                    /><template v-if="item.account.crypto_wallet">,
                    </template>
                  </template>
                  <template v-if="item.account.crypto_wallet">
                    <ValueHelper
                        :value="item.account.crypto_wallet"
                    />
                  </template>
                </template>

                <template v-if="isBusinessBank(item.account)">
                  <template v-if="item.account.full_name">
                    <ValueHelper
                        :value="item.account.full_name"
                    /><template v-if="item.account.iban">,
                    </template>
                  </template>
                  <template v-if="item.account.iban">
                    <ValueHelper
                        :value="item.account.iban"/>
                  </template>
                </template>
<!--                <template v-if="isIndividualBank(item.account)">-->
<!--                  <ValueHelper-->
<!--                          :value="item.account"-->
<!--                          :deep="'bank.currentTranslate.name'"/>,-->
<!--                  <ValueHelper-->
<!--                          :value="item.account.card_number"/>,-->
<!--                  <ValueHelper-->
<!--                          :value="item.account.phone"/>-->
<!--                </template>-->

<!--                <template v-if="isBusinessBank(item.account)">-->
<!--                  <ValueHelper-->
<!--                          :value="item.account.full_name"/>,-->
<!--                  <ValueHelper-->
<!--                          :value="item.account.iban"/>-->
<!--                </template>-->
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_YourComment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.user_comment"
                />
              </div>
            </div>
            <div class="custom-col custom-col--50">
              <div class="transaction-info__label">
                {{$t('requestFunds_AdminComment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                        :value="item.admin_comment"
                />
              </div>
            </div>


          </div>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
  import {REQUEST_FUNDS_STATUSES, ACCOUNT_BANK_WISE, ACCOUNT_BANK_CRYPTO} from "../../../../../staticData/staticVariables";
  import TableUserNameRow from "../../../../coreComponents/TableComponents/TableUserNameRow/TableUserNameRow";
  import {accountsHelperMixin} from "../../../../../mixins/account/accountMixin";
  import TooltipTextHelper from "../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import {requestFunds} from "../../../../../mixins/requestFundsMixins/requestFunds";


  export default {
    name: "InfoRequestFundsPopup",
    components: {
      TooltipTextHelper,
      TableUserNameRow,
      ValueHelper,
      StatusIcoBtn,
      Modal,
    },

    mixins: [accountsHelperMixin, requestFunds],

    props: {
      item: Object,
    },

    data() {
      return {
        REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,
        ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
        ACCOUNT_BANK_CRYPTO: ACCOUNT_BANK_CRYPTO,
      }
    }
  }

</script>

<style lang="scss">

</style>

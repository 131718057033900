var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"section-label",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['requestFunds_Status',])}}}),_vm._v(" "+_vm._s(_vm.$t('requestFunds_Status.localization_value.value'))+" ")]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col custom-col--33 custom-col--md-100",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
      'requestFunds_Status',
      _vm.REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.translation,
      _vm.REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.translation,
      _vm.REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.translation,
      ])}}}),(_vm._.has(_vm.RF.data.accountType, 'bank'))?_c('DefaultSelect',{class:{'ui-no-valid': _vm.RF.validation.status},attrs:{"options":_vm.options,"label":_vm.$t('requestFunds_Status.localization_value.value'),"errorTxt":_vm.$t(`${_vm.RF.validationTranslate.status}.localization_value.value`),"error":_vm.RF.validation.status,"selected":_vm.RF.data.status,"clearable":false,"disabled":_vm.RF.data.statusLock &&
            (_vm.RF.data.accountType.bank.id === _vm.ACCOUNT_BANK_PAYONEER.id || _vm.RF.data.accountType.bank.id === _vm.ACCOUNT_BANK_PRIVAT.id),"otherValue":'translation'},on:{"change":_vm.changeStatus}}):_vm._e()],1)]),_c('div',{staticClass:"custom-row"},[_c('div',{staticClass:"custom-col",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate(['requestFunds_ignoreAmountLimit',])}}}),_c('DefaultCheckbox',{attrs:{"label":_vm.$t('requestFunds_ignoreAmountLimit.localization_value.value')},model:{value:(_vm.RF.data.ignoreAmountLimit),callback:function ($$v) {_vm.$set(_vm.RF.data, "ignoreAmountLimit", $$v)},expression:"RF.data.ignoreAmountLimit"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <modal
          @close="$emit('close')"
          class="custom-popup"
  >
    <template slot="header">
      <!--<template v-if="isUpload">-->
      <!--Su-->
      <!--</template>-->
      <!--<template v-else>-->
      <div
              v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_RequestList'])"></div>
        {{ $t('requestFunds_RequestList.localization_value.value') }}
      </div>
      <!--</template>-->
    </template>
    <template slot="body">
      <div class="custom-popup__content mb-4">
        <div class="custom-row">
        </div>
        <div class="custom-row">
          <div class="custom-col">
            <div class="transfer-etsy">
              <div class="transfer-etsy__items">
                <div class="transfer-etsy__item"
                     v-for="(item, index) in items"
                     :key="index"
                >
                  <!--<div class="transfer-etsy__remove"></div>-->
                  <div class="transfer-etsy__right">
                    <div class="transfer-etsy__name">
                      #{{ item.id }}
                    </div>
                    <div class=""
                         v-if="_.indexOf(successIDs, item.id) > -1"
                    ><StatusIcoBtn :type="'delivered'" class="mr-2"/></div>

                    <div class=""
                         v-else-if="_.indexOf(errorIDs, item.id) > -1"
                    ><StatusIcoBtn :type="'rejected'" class="mr-2"/></div>

                    <div class=""
                         v-else-if="isUpload"
                    ><StatusIcoBtn :type="'in-transit'" class="mr-2"/></div>
                  </div>
                </div>


                <DotsShadowPreloader
                        class="mt-3"
                        v-if="successIDs.length + errorIDs.length < items.length && isUpload"
                />

                <!--<div class="transfer-etsy__item">-->
                <!--<div class="transfer-etsy__remove"></div>-->
                <!--<div class="transfer-etsy__right">-->
                <!--<div class="transfer-etsy__name">-->
                <!--Big Title of the Product Here with Small Description-->
                <!--</div>-->
                <!--<div class="transfer-etsy__success"></div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<div class="transfer-etsy__item">-->
                <!--<div class="transfer-etsy__remove"></div>-->
                <!--<div class="transfer-etsy__right">-->
                <!--<div class="transfer-etsy__name">transfer-etsy-->
                <!--Big Title of the Product Here with Small Description-->
                <!--</div>-->
                <!--<div class="transfer-etsy__success"></div>-->
                <!--</div>-->
                <!--</div>-->
              </div>

            </div>
          </div>

        </div>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_cancel',
          'requestFunds_massPaidATM',
        ])"></div>
        <span
                class="site-link site-link--alt buy-label__btn-i mr-3"
                @click="$emit('close')"
                v-if="successIDs.length - errorIDs.length === 0"
        >
          {{ $t('common_cancel.localization_value.value') }}
        </span>

        <MainButton
                :value="$t('requestFunds_massPaidATM.localization_value.value')"
                class="buy-label__btn-i"
                @click.native="upload"
                v-if="!isUpload"
        />
      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import MainButton from '../../../../UI/buttons/MainButton/MainButton.vue'
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import DotsShadowPreloader from "../../../../coreComponents/preloaders/DotsShadowPreloader/DotsShadowPreloader";
  import {requestFundsTable} from "../../../../../mixins/requestFundsMixins/requestFunds";
  import {REQUEST_FUNDS_STATUSES} from "../../../../../staticData/staticVariables";
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";

  export default {
    name: "MultipleRequestFundsPopup",
    components: {
      StatusIcoBtn,
      DotsShadowPreloader,
      Modal,
      MainButton,
    },

    mixins: [routeFilter, requestFundsTable],

    props: {
      items: [Array],
    },

    computed: {
      getSocketItem() {
        return this.requestFundsSocketItem
      }
    },

    watch: {
      getSocketItem(newVal) {
        let responseStatus = newVal?.balance_output?.status

        if(responseStatus === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value){
          this.successIDs.push(newVal?.balance_output.id)
        }
        if(responseStatus === REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value ||
          responseStatus === REQUEST_FUNDS_STATUSES.ERROR_OUTPUT_STATUS.value){
          this.errorIDs.push(newVal?.balance_output.id)
        }
      },
    },

    data() {
      return {
        successIDs: [],
        errorIDs: [],
        isUpload: false,

        requestFundsSocketItem: null,
      }
    },

    mounted() {

    },

    // beforeDestroy() {
    //   if(this.$store.getters.getEtsyTransferSocket){
    //     this.destroySocketChannel('EtsyTransfer', 'setEtsyTransferSocket')
    //   }
    // },

    methods: {
      upload() {
        this.sendMassRequestFunds()
        this.isUpload = true
      },
    }

  }

</script>

<style scoped lang="scss">
  @import '../../../../../scss/colors';
  @import '../../../../../scss/mixins/mixins';

  .transfer-etsy {
    width: 100%;

    &__items {

    }

    &__item {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__remove {
      width: 35px;
      min-width: 35px;
      display: flex;
      height: 35px;
      background-image: url("../../../../../assets/img/UI-group/delete-icon-transfer.svg");
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
      margin-left: -20px;

      @include for-550 {
        margin-left: -15px;
      }
    }

    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #F8F4EE;
      padding-bottom: 5px;

      @include for-550 {
        max-width: calc(100% - 15px);
      }
    }

    &__name {
      width: 100%;
      max-width: 325px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
      font-size: 14px;

      @include for-550 {
        max-width: calc(100% - 40px);
      }
    }

    &__success {
      width: 18px;
      height: 18px;
      background: url("../../../../../assets/img/common/success-mark.svg") center/contain no-repeat;

      @include for-550 {
        margin-left: 10px;
      }
    }

    &__error {
      width: 18px;
      height: 18px;
      background: url("../../../../../assets/img/common/error-mark.svg") center/contain no-repeat;

      @include for-550 {
        margin-left: 10px;
      }
    }

    &__in-progress {
      width: 18px;
      height: 18px;
      background: url("../../../../../../public/img/common/status/in-transit.svg") center/contain no-repeat;

      @include for-550 {
        margin-left: 10px;
      }
    }

  }


</style>

<template>
  <div class="fragment">
    <template v-if="$store.getters.getRequestFunds.length > 0 && $store.getters.getRequestFundsLoading !== true">

      <div class="fragment"
           v-if="$store.getters.getRequestFundsTotals['exceeded_limit_info']['atm']['exceeded'] && getUserRequestATM"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_exceededLimitInfoATM'])"></div>
        <HorizontalWarning
            class="horizontal-warning-scoped"
            :ico="true"
            :text="$t('requestFunds_exceededLimitInfoATM.localization_value.value')"
        />
      </div>

      <div class="fragment"
           v-if="$store.getters.getRequestFundsTotals['exceeded_limit_info']['ukrainian_bank']['exceeded'] && getUserRequestATM"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_exceededLimitInfoUkrBank'])"></div>
        <HorizontalWarning
            class="horizontal-warning-scoped"
            :ico="true"
            :text="$t('requestFunds_exceededLimitInfoUkrBank.localization_value.value')"
        />
      </div>


      <HorizontalWarning
          v-if="$store.getters.getRequestFundsTotals && isAdmin"
          class="horizontal-warning-scoped"
          :ico="false"
          :tooltip="true"
      >
        <template slot="tooltip">
          <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                'requestFunds_Payonner',
                'requestFunds_Privat',
                'requestFunds_USD',
                'requestFunds_UAH',
              ])"></div>
            <span class="mr-3">
                {{ $t('requestFunds_Payonner.localization_value.value') }}:
                <ValueHelper
                    :value="$store.getters.getRequestFundsTotals['totals']['payonner']"
                />
              </span>
            <span class="mr-3">
               {{ $t('requestFunds_Privat.localization_value.value') }}:
                <ValueHelper
                    :value="$store.getters.getRequestFundsTotals['totals']['privat']"
                />
              </span>
            <span class="mr-3">
               {{ $t('requestFunds_USD.localization_value.value') }}:
                <ValueHelper
                    :value="$store.getters.getRequestFundsTotals['totals']['fop']['usd']"
                />
              </span>
            <span class="mr-3">
                {{ $t('requestFunds_UAH.localization_value.value') }}:
                <ValueHelper
                    :value="$store.getters.getRequestFundsTotals['totals']['fop']['uah']"
                />
              </span>
            <!--              <span class="mr-3">-->
            <!--                {{ $t('requestFunds_Payonner.localization_value.value') }}:-->
            <!--                {{$store.getters.getRequestFundsCommonList.totals.payonner}}-->
            <!--              </span>-->
            <!--              <span class="mr-3">-->
            <!--               {{ $t('requestFunds_Privat.localization_value.value') }}:-->
            <!--               {{$store.getters.getRequestFundsCommonList.totals.privat}}-->
            <!--              </span>-->
            <!--              <span class="mr-3">-->
            <!--               {{ $t('requestFunds_USD.localization_value.value') }}:-->
            <!--                {{$store.getters.getRequestFundsCommonList.totals.fop.usd}}-->
            <!--              </span>-->
            <!--              <span class="mr-3">-->
            <!--                {{ $t('requestFunds_UAH.localization_value.value') }}:-->
            <!--                {{$store.getters.getRequestFundsCommonList.totals.fop.uah}}-->
            <!--              </span>-->
          </div>
        </template>
      </HorizontalWarning>




      <div class="table-filter-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          // 'requestFunds_TheDailyLimit',
          // 'requestFunds_LimitReached',
          // 'requestFunds_ToGetYourPayments',
          'requestFunds_ID',
          'requestFunds_Date',
          'requestFunds_balance',
          'requestFunds_frozen',
          'requestFunds_Commission',
          'requestFunds_Total',
          'requestFunds_Partial',
          'requestFunds_Transactions',
          'requestFunds_Account',
          'requestFunds_accountBank',
          'requestFunds_YourComment',
          'requestFunds_AdminComment',
          'requestFunds_Status',
          'requestFunds_Paid',
          'requestFunds_PaidATM',
          'requestFunds_Rejected',
          'requestFunds_Error',
          'requestFunds_Unapproved',
          'common_edit',
          'requestFunds_ATM',
          'requestFunds_Output',
          'common_cancellation',
        ])"></div>

<!--        <HorizontalWarning-->
<!--                class="horizontal-warning-scoped"-->
<!--                :ico="true"-->
<!--                :text="$t('requestFunds_TheDailyLimit.localization_value.value')"-->
<!--                :tooltip="true"-->
<!--        >-->
<!--          <template slot="tooltip">-->
<!--            <v-popover-->
<!--                    class="site-tooltip"-->
<!--                    offset="5"-->
<!--                    placement="top-left"-->
<!--                    trigger="hover"-->
<!--            >-->
<!--          <span>-->
<!--            <TooltipBtn/>-->
<!--          </span>-->
<!--              <template slot="popover">-->
<!--                <p><b>{{ $t('requestFunds_LimitReached.localization_value.value') }}</b></p>-->
<!--                <p>-->
<!--                  {{ $t('requestFunds_ToGetYourPayments.localization_value.value') }}-->
<!--                </p>-->
<!--              </template>-->
<!--            </v-popover>-->
<!--          </template>-->
<!--        </HorizontalWarning>-->

        <div class="site-table-wrap small-table products-table">
          <table class="site-table" v-if="!isMobileFunc()">
            <thead>
            <tr>
              <th v-if="isAdmin"></th>
              <th>
                {{ $t('requestFunds_ID.localization_value.value') }}
                <SortButton :sortParams="sortParams" :defaultSort="'DESC'" :sortName="'sortId'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>
                {{ $t('requestFunds_Date.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortCreatedAt'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="isAdmin">
                {{ $t('common_user.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortUser'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th v-if="isAdmin">
                {{$t('requestFunds_balance.localization_value.value')}}
                <SortButton :sortParams="sortParams" :sortName="'sortUserBalance'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th :class="{'white-space-normal-i' : isAdmin }" v-if="isAdmin">
                {{$t('requestFunds_balance.localization_value.value')}} Payoneer
              </th>
              <th v-if="isAdmin">{{$t('requestFunds_frozen.localization_value.value')}}</th>
              <th>{{ $t('requestFunds_Commission.localization_value.value') }}</th>
              <th :class="{'white-space-nowrap' : isAdmin }">{{ $t('requestFunds_Total.localization_value.value') }}</th>
              <th :class="{'white-space-nowrap' : isAdmin }">
                {{ $t('requestFunds_Partial.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortOutputAmount'" @changeSort="data => $emit('changeSort', data)"/>
              </th>
              <th>{{ $t('requestFunds_Transactions.localization_value.value') }}</th>
              <th>{{ $t('requestFunds_Account.localization_value.value') }}</th>
              <th v-if="isAdmin">{{$t('requestFunds_accountBank.localization_value.value')}}</th>
              <th :class="{'white-space-normal-i' : isAdmin }">{{ $t('requestFunds_YourComment.localization_value.value') }}</th>
              <th :class="{'white-space-normal-i' : isAdmin }">{{ $t('requestFunds_AdminComment.localization_value.value') }}</th>
              <th>
                {{ $t('requestFunds_Status.localization_value.value') }}
                <SortButton :sortParams="sortParams" :sortName="'sortStatus'" @changeSort="data => $emit('changeSort', data)" v-if="false"/>
              </th>
              <th>{{ $t('common_manage.localization_value.value') }}</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(item, index) in $store.getters.getRequestFunds"
                :key="index"
            >
              <!--v-bind:class="{'site-table__draft-row': item.status === 'new'}"-->
              <td v-if="isAdmin">
                <DefaultCheckbox
                        v-if="showRequestCheckbox(item)"
                        class="mt-1"
                        :selectedNow="selectedNow"
                        :dataValue="item.id"
                />
              </td>
              <td>
            <span class="table-link btn-style" @click="showRequestFunds(item.id)">
               #{{ item.id }}
            </span>
              </td>
              <td> {{ item.created_at | moment("DD MMM, YYYY H:mm") }}</td>

              <td v-if="isAdmin">
                <TableUserColumn
                        v-if="item.account"
                        :item="item.account"
                />
              </td>
              <td v-if="isAdmin" align="right">
                <ValueHelper
                    :value="item.account"
                    :deep="'user.balance'"
                />
              </td>
              <td v-if="isAdmin" align="right">
                <ValueHelper
                    :value="item.account"
                    :deep="'user.user_balances.0.balance'"
                />
              </td>
              <td v-if="isAdmin" align="right">
                <ValueHelper
                    :value="item.account"
                    :deep="'user.frozen_balance'"
                />
              </td>

              <td align="right">
                <ValueHelper
                        :value="item.total_commission_amount"
                />
              </td>
              <td align="right">
                <ValueHelper
                        :value="item.payment_transaction_total_amount"
                />
              </td>
              <td align="right">
                <ValueHelper
                        :value="item.output_amount"
                />
              </td>
              <td>
                <div class="table-row" v-if="item.payment_transactions.length > 0">
                  <div class="transactions-row"
                  >
                    <span v-for="(itemTransactions, index) in item.payment_transactions"
                          :key="index"
                          class="table-link btn-style"
                          @click="showTransactionInfo(itemTransactions)">
                      <template
                              v-if="index === 0">
                        #{{ itemTransactions.id }}
                      </template>
                    </span>

                  </div>
<!--                  <TooltipTextHelper-->
<!--                      v-if="item.payment_transactions.length > 1"-->
<!--                      :widthPopover="327"-->
<!--                  >-->
<!--                    <template slot="text">-->
<!--                     <span>-->
<!--                        <CountCircle-->
<!--                            :value="'+' + (item.payment_transactions.length - 1)"-->
<!--                        />-->
<!--                      </span>-->
<!--                    </template>-->
<!--                    <template slot="paragraph">-->
<!--                      <p><b>{{ $t('requestFunds_Transactions.localization_value.value') }}</b></p>-->
<!--                      <p>-->
<!--                        <template v-for="(itemTransactions, index) in item.payment_transactions">-->
<!--                           <span class="table-link fsz11 btn-style tran-0 mr-1"-->
<!--                                 :key="index"-->
<!--                                 v-if="index !== 0"-->
<!--                                 @click="showTransactionInfo(itemTransactions)"-->
<!--                           >#{{ itemTransactions.id }} <span-->
<!--                               v-if="index < item.payment_transactions.length - 1">,</span> </span>-->
<!--                        </template>-->
<!--                      </p>-->
<!--                    </template>-->
<!--                  </TooltipTextHelper>-->
                  <v-popover
                          class="site-tooltip"
                          offset="5"
                          placement="top-left"
                          trigger="hover"
                          v-if="item.payment_transactions.length > 1"
                  >
                <span>
                  <CountCircle
                          :value="'+' + (item.payment_transactions.length - 1)"
                  />

                </span>
                    <template slot="popover">
                      <p><b>{{ $t('requestFunds_Transactions.localization_value.value') }}</b></p>
                      <p>
                        <template v-for="(itemTransactions, index) in item.payment_transactions">
                           <span class="table-link fsz11 btn-style tran-0 mr-1"
                                 :key="index"
                                 v-if="index !== 0"
                                 @click="showTransactionInfo(itemTransactions)"
                           >#{{ itemTransactions.id }} <span
                                   v-if="index < item.payment_transactions.length - 1">,</span> </span>
                        </template>
                      </p>
                    </template>
                  </v-popover>
                </div>
              </td>
              <td>
                <TooltipTextHelper
                        :fullTextWidth="90"
                >
                  <template slot="text">

                    <template v-if="item.account.bank_id === 2">
                      {{item.account['bank'].name}}
                    </template>

                    <router-link class="table-link btn-style" :to="openAccountForEdit(item.account)">
                      <template v-if="isIndividualBank(item.account)">
                        <template v-if="item.account['bank']['currentTranslate']['name']">
                          <ValueHelper
                              :value="item.account"
                              :deep="'bank.currentTranslate.name'"
                          /><template v-if="item.account.card_number || item.account.email || item.account.phone
                          || (item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name) ||
                          (item.account.bank_id === ACCOUNT_BANK_CRYPTO.id && item.account.crypto_name)">,
                          </template>
                        </template>
                        <template v-if="item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name">
                          <ValueHelper
                              :value="item.account.full_name"/>
                        </template>
                        <template v-if="item.account.bank_id === ACCOUNT_BANK_RAMP.id && item.account.middle_name">
                          <ValueHelper
                              :value="item.account.middle_name"/>,
                        </template>

                        <template v-if="item.account.card_number">
                          <ValueHelper
                              :value="item.account.card_number"
                          /><template v-if="item.account.email || item.account.phone">,
                          </template>
                        </template>

                        <template v-if="item.account.email">
                          <ValueHelper
                              :value="item.account.email"
                          /><template v-if="item.account.phone">,
                          </template>
                        </template>

                        <template v-if="item.account.phone">
                          <ValueHelper
                              :value="item.account.phone"
                          />
                        </template>

                        <template v-if="item.account.crypto_name">
                          <ValueHelper
                              :value="item.account.crypto_name"
                          /><template v-if="item.account.crypto_wallet">,
                          </template>
                        </template>
                        <template v-if="item.account.crypto_wallet">
                          <ValueHelper
                              :value="item.account.crypto_wallet"
                          />
                        </template>
                      </template>

                      <template v-if="isBusinessBank(item.account)">
                        <template v-if="item.account.full_name">
                          <ValueHelper
                              :value="item.account.full_name"
                          /><template v-if="item.account.iban">,
                          </template>
                        </template>
                        <template v-if="item.account.iban">
                          <ValueHelper
                              :value="item.account.iban"/>
                          <template v-if="CURRENCIES.UAH.id === item.account.currency_id">&nbsp;UAH</template>
                          <template v-if="CURRENCIES.USD.id === item.account.currency_id">&nbsp;USD</template>
                        </template>
                      </template>
                    </router-link>
                  </template>
                  <template slot="paragraph">

                    <template v-if="item.account.bank_id === 2">
                      {{item.account['bank'].name}}
                    </template>

                    <template v-if="isIndividualBank(item.account)">
                      <template v-if="item.account['bank']['currentTranslate']['name']">
                        <ValueHelper
                            :value="item.account"
                            :deep="'bank.currentTranslate.name'"
                        /><template v-if="item.account.card_number || item.account.email || item.account.phone
                        || (item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name) ||
                        (item.account.bank_id === ACCOUNT_BANK_CRYPTO.id && item.account.crypto_name)">,
                        </template>
                      </template>

                      <template v-if="item.account.bank_id === ACCOUNT_BANK_WISE.id && item.account.full_name">
                        <ValueHelper
                            :value="item.account.full_name"/>
                      </template>
                      <template v-if="item.account.bank_id === ACCOUNT_BANK_RAMP.id && item.account.middle_name">
                        <ValueHelper
                            :value="item.account.middle_name"/>,
                      </template>

                      <template v-if="item.account.card_number">
                        <ValueHelper
                            :value="item.account.card_number"
                        /><template v-if="item.account.email || item.account.phone">,
                        </template>
                      </template>

                      <template v-if="item.account.email">
                        <ValueHelper
                            :value="item.account.email"
                        /><template v-if="item.account.phone">,
                        </template>
                      </template>

                      <template v-if="item.account.phone">
                        <ValueHelper
                            :value="item.account.phone"
                        />
                      </template>
                      <template v-if="item.account.crypto_name">
                        <ValueHelper
                            :value="item.account.crypto_name"
                        /><template v-if="item.account.crypto_wallet">,
                        </template>
                      </template>
                      <template v-if="item.account.crypto_wallet">
                        <ValueHelper
                            :value="item.account.crypto_wallet"
                        />
                      </template>
                    </template>

                    <template v-if="isBusinessBank(item.account)">
                      <template v-if="item.account.full_name">
                        <ValueHelper
                            :value="item.account.full_name"
                        /><template v-if="item.account.iban">,
                        </template>
                      </template>
                      <template v-if="item.account.iban">
                        <ValueHelper
                            :value="item.account.iban"/>
                        <template v-if="CURRENCIES.UAH.id === item.account.currency_id">&nbsp;UAH</template>
                        <template v-if="CURRENCIES.USD.id === item.account.currency_id">&nbsp;USD</template>
                      </template>
                    </template>
                  </template>
                </TooltipTextHelper>


              </td>
              <td v-if="isAdmin">
                <TooltipTextHelper
                    :fullTextWidth="90"
                >
                  <template slot="text">
                    <ValueHelper
                        :value="item.account"
                        :deep="'bank.currentTranslate.name'"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                        :value="item.account"
                        :deep="'bank.currentTranslate.name'"
                    />
                  </template>
                </TooltipTextHelper>
              </td>
              <td>
                <TooltipTextHelper
                    :widthPopover="120"
                    :fullTextWidth="70"
                >
                  <template slot="text">
                    <ValueHelper
                            :value="item.user_comment"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                            :value="item.user_comment"
                    />
                  </template>
                </TooltipTextHelper>
              </td>
              <td>
                <TooltipTextHelper
                        :fullTextWidth="70"
                >
                  <template slot="text">
                    <ValueHelper
                            :value="item.admin_comment"
                    />
                  </template>
                  <template slot="paragraph">
                    <ValueHelper
                            :value="item.admin_comment"
                    />
                  </template>
                </TooltipTextHelper>
              </td>
              <td class="pr-3">

                <div class="d-flex align-items-center">

                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'star'" class="mr-2"/>
                    {{ $t('requestFunds_New.localization_value.value') }}
                  </template>
                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.IN_PROCESS_OUTPUT_STATUS.value ||
                      item.status === REQUEST_FUNDS_STATUSES.WAIT_CONFIRM_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'in-transit'" class="mr-2"/>
                    {{ $t('requestFunds_InProcess.localization_value.value') }}
                  </template>

                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'delivered'" class="mr-2"/>
                    <template v-if="item.public_output_info === null">
                      <template v-if="item.atm === 0">{{ $t('requestFunds_Paid.localization_value.value') }}</template>
                      <template v-if="item.atm === 1">{{ $t('requestFunds_PaidATM.localization_value.value') }}</template>
                    </template>
                    <template v-else>
                      {{item.public_output_info}}
                    </template>
                  </template>

                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.REJECTED_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'rejected'" class="mr-2"/>
                    <template v-if="canRequestATM">
                      <TooltipTextHelper
                              :text="$t('requestFunds_Rejected.localization_value.value')"
                              :paragraph="item.rejected_reason"
                      />
                    </template>
                    <template v-else>{{ $t('requestFunds_Rejected.localization_value.value') }}</template>

                  </template>

                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.ERROR_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'cancel-red'" class="mr-2"/>
                    {{ $t('requestFunds_Error.localization_value.value') }}
                  </template>
                  <template v-if="item.status === REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value">
                    <StatusIcoBtn :type="'declined'" class="mr-2"/>
                    {{ $t('requestFunds_Unapproved.localization_value.value') }}
                  </template>
                </div>

              </td>
              <td class="table-right">
                <!--BTN FOR ADMIN-->
                <template v-if="isAdmin">
                  <div class="table-right table-manage-list table-manage-list--small">
                    <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                      <template slot="item">
                        <LinkButton
                            :value="$t('common_edit.localization_value.value')"
                            :type="'edit'"
                            :link="getLinkEdit(item)"
                            :disabled="$store.getters.requestFundsBtn"
                        />
                      </template>
                      <template slot="item"
                                v-if="item.status !== REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.value &&
                                      item.status !== REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.value"
                      >
                        <LinkButton
                            :value="$t('common_cancellation.localization_value.value')"
                            :type="'delete'"
                            @click.native="cancelItem(item.id)"
                            :disabled="$store.getters.requestFundsBtn"
                        />
                      </template>
                      <template slot="item" v-if="item.balance_output_invoice === null">
                        <LinkButton
                            :value="$t('invoices_createInvoice.localization_value.value')"
                            :type="'dog'"
                            @click.native="createInvoiceFunds(item.id)"
                        />
                      </template>
                      <template slot="item" v-if="item.balance_output_invoice !== null">
                        <LinkButton
                            :value="$t('invoices_editInvoice.localization_value.value')"
                            :type="'dog'"
                            @click.native="editInvoiceFunds(item.id, item.balance_output_invoice.id)"
                        />
                      </template>
                    </ManagerButton>
                  </div>
                </template>
                <!--BTN FOR USER-->
                <template v-if="!isAdmin">
                  <div class="table-right table-manage-list table-manage-list--small">
                    <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton">
                      <template slot="item"
                                v-if="getUserRequestATM &&
                                canRequestATM(item) &&
                                canShowManageBtns(item)">
                        <LinkButton
                            :value="$t('requestFunds_ATM.localization_value.value')"
                            :type="'atm'"
                            @click.native="$emit('sendRequestFunds', item.id)"
                            :disabled="$store.getters.requestFundsBtn"
                        />
                      </template>
                      <template slot="item"
                                v-if="item.status === REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.value&&
                                canShowManageBtns(item)"
                      >
                        <LinkButton
                            :value="$t('common_cancellation.localization_value.value')"
                            :type="'delete'"
                            @click.native="cancelItem(item.id)"
                            :disabled="$store.getters.requestFundsBtn"
                        />
                      </template>
                      <template slot="item" v-if="item.balance_output_invoice === null">
                        <LinkButton
                            :value="$t('invoices_createInvoice.localization_value.value')"
                            :type="'dog'"
                            @click.native="createInvoiceFunds(item.id)"
                        />
                      </template>
                      <template slot="item" v-if="item.balance_output_invoice !== null">
                        <LinkButton
                            :value="$t('invoices_editInvoice.localization_value.value')"
                            :type="'dog'"
                            @click.native="editInvoiceFunds(item.id, item.balance_output_invoice.id)"
                        />
                      </template>
                    </ManagerButton>
                  </div>
                </template>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="table-card" v-if="isMobileFunc()">
            <div class="table-card__list">
              <div class="table-card__item"
                   v-for="(item, index) in $store.getters.getRequestFunds"
                   :key="index"
              >
<!--                   v-bind:class="{'draft-row': item.status === 'new'}"-->
                <RequestFundsTableMobile
                        :item="item"
                        :linkEdit="getLinkEdit(item)"
                        :selectedNow="selectedNow"
                        @cancelItem="cancelItem(item.id)"
                        @sendRequestFunds="$emit('sendRequestFunds', item.id)"
                        @sendRequestFundsNoAPI="$emit('sendRequestFundsNoAPI', item.id)"
                        @showTransactionInfo="showTransactionInfo"
                        @createInvoiceFunds="createInvoiceFunds"
                        @editInvoiceFunds="editInvoiceFunds"
                        @showRequestFunds="showRequestFunds(item.id)"
                />
              </div>
            </div>
          </div>

          <div class="table-bottom-btn">
            <div class="table-bottom-btn__inner">
              <ShowMore
                      v-if="$store.getters.getRequestFundsCommonList.next_page_url !== null && $store.getters.getRequestFunds.length > 0"
                      @click.native="$emit('showMore')"
                      v-bind:class="{'disabled-btn' : $store.getters.getNextRequestFundsPage}"
                      :count="$store.getters.getRequestFundsCommonList.total - $store.getters.getRequestFundsForPage * $store.getters.getRequestFundsCommonList.current_page < $store.getters.getRequestFundsForPage ?
                  $store.getters.getRequestFundsCommonList.total - $store.getters.getRequestFundsForPage * $store.getters.getRequestFundsCommonList.current_page:
                  $store.getters.getRequestFundsForPage"
              />
              <ExportBtn
                  v-if="isAdmin"
                  @downloadFiles="type => $emit('downloadFiles', type)"
                  class="table-bottom-btn__right"/>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getRequestFundsLoading === false && $store.getters.getRequestFunds.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>



    <InfoRequestFundsPopup
            v-if="isModalInfoRequestFundsPopup"
            :item="itemObject"
            @openInfo="item => showTransactionInfo(item)"
            @close="isModalInfoRequestFundsPopup = false"
    />

    <InfoIDPopup
            v-if="isModalInfoIDPopup"
            :userTabs="{balanceOutput: {active: true}}"
            @close="isModalInfoIDPopup = false"
    />

    <TransactionInfo
            @close="isModalTransactionInfo = false"
            v-if="isModalTransactionInfo"
            :transactionUrl="true"
            :userTabs="{balanceOutput: {active: true}}"
    />

    <FundsInvoicePopup
        v-if="isModalInvoice"
        :idFund="idFund"
        :idInvoice="idInvoice"
        @close="isModalInvoice = false; idFund = null; idInvoice = null"
        @reload="$emit('reload')"
    />

  </div>

</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import CountCircle from "../../../../../../coreComponents/CountCircle/CountCircle";
  import HorizontalWarning from "../../../../../../coreComponents/HorizontalWarning/HorizontalWarning";
  // import TooltipBtn from "../../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import ValueHelper from "../../../../../../coreComponents/ValueHelper/ValueHelper";
  import RequestFundsTableMobile from "./RequestFundsTableMobile/RequestFundsTableMobile";
  import {
    ACCOUNT_BANK_BENTO_VISA_CARD,
    ACCOUNT_BANK_PAYONEER, ACCOUNT_BANK_PRIVAT,
    ACCOUNT_BANK_CRYPTO,
    PAYMENTS_SYSTEMS,
    ACCOUNT_BANK_WISE,
    REQUEST_FUNDS_STATUSES,
    REQUEST_FUNDS_TYPES, ACCOUNT_BANK_TYPE, ACCOUNT_BANK_RAMP, CURRENCIES
  } from "../../../../../../../staticData/staticVariables";
  import {accountsHelperMixin} from "../../../../../../../mixins/account/accountMixin";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import TransactionInfo from "../../../../../CusPaymentsModule/popups/TransactionInfo/TransactionInfo";
  import InfoIDPopup from "../../../../../CusPaymentsModule/popups/InfoIDPopup/InfoIDPopup";
  import InfoRequestFundsPopup from "../../../../popups/InfoRequestFundsPopup/InfoRequestFundsPopup";
  import {requestFundsTable} from "../../../../../../../mixins/requestFundsMixins/requestFunds";
  import ExportBtn from "../../../../../../coreComponents/Buttons/Export/Export";
  import SortButton from "@/components/UI/buttons/SortButton/SortButton";
  import FundsInvoicePopup from "@/components/modules/RequestFundsModule/popups/FundsInvoicePopup/FundsInvoicePopup";


  export default {
    name: "RequestFundsTable",

    components: {
      FundsInvoicePopup,
      SortButton,
      ExportBtn,
      InfoRequestFundsPopup,
      InfoIDPopup,
      TransactionInfo,
      TooltipTextHelper,
      RequestFundsTableMobile,
      ValueHelper,
      TableUserColumn,
      NoResult,
      // TooltipBtn,
      ShowMore,
      DefaultCheckbox,
      LinkButton,
      ManagerButton,
      StatusIcoBtn,
      CountCircle,
      HorizontalWarning,
    },

    mixins: [mixinDetictingMobile, accountsHelperMixin, requestFundsTable],

    props: {
      selectedNow: Boolean,
      countFilterParams: Number,
      sortParams: Object,
    },

    watch: {
      requestFundsSocketItem(newVal) {
        console.log(newVal);
      }
    },


    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        // isModalProductsToWarehouse: false,
        REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,
        PAYMENTS_SYSTEMS: PAYMENTS_SYSTEMS,

        ACCOUNT_BANK_PAYONEER: ACCOUNT_BANK_PAYONEER,
        ACCOUNT_BANK_BENTO_VISA_CARD: ACCOUNT_BANK_BENTO_VISA_CARD,
        ACCOUNT_BANK_PRIVAT: ACCOUNT_BANK_PRIVAT,
        ACCOUNT_BANK_WISE: ACCOUNT_BANK_WISE,
        ACCOUNT_BANK_CRYPTO: ACCOUNT_BANK_CRYPTO,
        ACCOUNT_BANK_RAMP: ACCOUNT_BANK_RAMP,

        isModalTransactionInfo: false,
        isModalInfoIDPopup: false,
        isModalInfoRequestFundsPopup: false,
        itemObject: null,

        requestFundsSocketItem: null,

        CURRENCIES: CURRENCIES,

        isModalInvoice: false,
        idFund: null,
        idInvoice: null,
      }
    },

    methods: {

      editInvoiceFunds(idFund, idInvoice) {
        this.idFund = idFund
        this.idInvoice = idInvoice
        this.isModalInvoice = true
      },

      createInvoiceFunds(idFund) {
        this.idFund = idFund
        this.isModalInvoice = true
      },

      showRequestFunds(id) {
        this.itemObject = null

        this.$store.dispatch('getRequestFunds', id).then(response => {
          this.itemObject = this.getRespData(response)
          this.isModalInfoRequestFundsPopup = true
        })
      },

      showTransactionInfo(item) {
        this.$store.dispatch('getCusPayments', item.id).then((response) => {
          let payment = this.getRespData(response)
          if (PAYMENTS_SYSTEMS.other.value === payment.payment_system) {
            this.isModalInfoIDPopup = true
          } else {
            this.isModalTransactionInfo = true
          }
        })
      },

      getLinkEdit(item) {
        if (item?.account?.type?.id === REQUEST_FUNDS_TYPES.INDIVIDUAL.id || item?.account?.type?.id === 3) {
          return this.$store.getters.GET_PATHS.financeRequestFundsIndividual + '/' + item.id
        }

        if (item?.account?.type?.id === REQUEST_FUNDS_TYPES.BUSINESS.id) {
          return this.$store.getters.GET_PATHS.financeRequestFundsBusiness + '/' + item.id
        }
      },

      closePopup() {
        // this.isModalProductsToWarehouse = false
      },

      cancelItem(id) {
        let text = {
          title: 'common_cancelConfirm',
          txt: '',
          yes: 'common_yes',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.requestFundsCancel(id)

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      openAccountForEdit(val){

        if (!val?.bank) {
          return '#'
        }

        let data = {
          route: this.$store.getters.GET_PATHS.accounts,
          itemId: val.id,
          bankTypeId: val["bank"]["type_id"],
        }
        let bankType = this._.find(ACCOUNT_BANK_TYPE , ['id', data.bankTypeId]);
        return `${data.route}/${bankType.name}/${data.itemId}`
      },
    }

  }

</script>

<style scoped lang="scss">


  .table-right {
    box-sizing: content-box;
    height: 40px;
  }

  .manager-btn {
    max-width: 162px;
  }

  .transactions-row {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 65px;
    position: relative;
    display: inline-block;
  }

  .site-table .table-link,
  .site-table tbody td,
  .site-table thead th {
    font-size: 12px;
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child {
    padding-right: 0;
    padding-left: 7px;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child {
    padding-right: 0;
    padding-left: 0;
  }

  td .status-ico {
    top: 0;
  }

  @media (max-width: 1450px) and (min-width: 1270px) {
    .site-table .table-link, .site-table tbody td, .site-table thead th{
      padding-right: 0 !important;
    }

    .manager-btn {
      max-width: 60px;
    }

    td:nth-child(3) {
      white-space: initial;
    }

    /deep/ .manager-btn__list.more>.link-btn:nth-last-child(2) {
      white-space: initial;
      word-break: break-all;
    }
  }

  @media (max-width: 1150px) {
    .horizontal-warning-scoped {
      margin-bottom: 0;
    }
  }

  @media (max-width: 440px) {
    /deep/ .horizontal-warning-scoped .horizontal-warning__text{
      padding-right: 27px;
    }
  }


  .white-space-normal-i{
    white-space: normal!important;
  }



</style>

<template>
  <div class="fragment">
    <RequestFundsBusinessUser/>
  </div>
</template>

<script>
  import RequestFundsBusinessUser from "./RequestFundsBusinessUser/RequestFundsBusinessUser";

  export default {
    name: "RequestFundsBusiness",
    components:{
      RequestFundsBusinessUser,
    },
  }
</script>

<style scoped>

</style>
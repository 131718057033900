<template>
  <div class="fragment">

<!--    <div class="horiz-block" v-if="isAdmin">-->
<!--      <div class="horiz-block__inner">-->
<!--        <div class="filter-parcel-track custom-row">-->
<!--          -->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <div class="content-top-line content-top-line--with-bottom-line">
      <div class="content-tabs content-tabs--separator">

      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                @toggleFilter="$emit('toggleFilter')"
                :showFilter="showFilter"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'common_selectAll',
        'requestFunds_SelectPaymentSystem',
        'requestFunds_ATM',
        'requestFunds_PayoneerOutput',
        'requestFunds_BentoOutput',
        'requestFunds_PrivatOutput',
        // 'requestFunds_WiseOutput',
        'requestFunds_WithoutAPIOutput',
        ])"></div>

        <div class="btn-left-block__list">
          <div class="btn-left-block__list__i align-items-center" v-if="isAdmin">
            <DefaultCheckbox
                class="scoped-margin"
                    :label="$t('common_selectAll.localization_value.value')"
                    @input="(val) => $emit('selectAll', val)"
            />
          </div>
          <div class="btn-left-block__list__i min-vw-335px" v-if="isAdmin">
            <DefaultSelect
                  class="w-100 white border-light"
                  :options="options"
                  :otherValue="'translation'"
                  :label="$t('requestFunds_SelectPaymentSystem.localization_value.value')"
                  @change="changeType"
            />
<!--                        :value="$t('requestFunds_Output.localization_value.value')"-->
            <MainButton class="btn-fit-content ml-3"
                        :value="$t('requestFunds_ATM.localization_value.value')"
                        v-bind:class="{'disabled-btn' : $store.getters.getRequestFundsBtn}"
                        @click.native="multipleRequestFunds"
            />
          </div>
        </div>
      </div>



      <div class="btn-right-block"
           v-bind:class="{
           'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
           'btn-right-block--mob' : !isAdmin
           }">
        <div class="admin-edit" @click="editTranslate(['requestFunds_IndividualRequest', 'requestFunds_BusinessRequest'])"></div>
        <!--<router-link :to="$store.getters.GET_PATHS.financeRequestFundsNoTr"-->
                     <!--v-if="isAdmin"-->
        <!--&gt;-->
          <!--<MainButton class="btn-fit-content brown ml-3 ml-0-adaptive"-->
                      <!--:value="$t('requestFunds_RequestNoTr.localization_value.value')"-->
                      <!--:ico="'ico'"-->
          <!--&gt;-->
            <!--<template slot="ico">-->
              <!--<PlusIconSVG class="btn-icon-plus"/>-->
            <!--</template>-->
          <!--</MainButton>-->
        <!--</router-link>-->

        <router-link :to="$store.getters.GET_PATHS.financeRequestFundsIndividual">
          <MainButton class="btn-fit-content brown ml-3"
                      :value="$t('requestFunds_IndividualRequest.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>

        <router-link :to="$store.getters.GET_PATHS.financeRequestFundsBusiness">
          <MainButton class="btn-fit-content ml-3"
                      :value="$t('requestFunds_BusinessRequest.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>


  </div>
</template>


<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {
    ACCOUNT_BANK_BENTO_VISA_CARD,
    ACCOUNT_BANK_PAYONEER,
    ACCOUNT_BANK_PRIVAT, ACCOUNT_BANK_RAMP, WITHOUT_API_TYPE,
  } from "../../../../../../../staticData/staticVariables";


  export default {
    name: "RequestFundsHead",
    components: {
      DefaultSelect,
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      DefaultCheckbox,
    },

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
    },

    data() {
      return {
        options: [
          ACCOUNT_BANK_PAYONEER,
          ACCOUNT_BANK_BENTO_VISA_CARD,
          ACCOUNT_BANK_RAMP,
          ACCOUNT_BANK_PRIVAT,
          WITHOUT_API_TYPE,
        ],

        type: null
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeType(item) {
        this.type = item
      },

      multipleRequestFunds() {
        if(this.type){
          this.$emit('multipleRequestFunds', this.type)
        }
      },
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";

  .min-vw-335px{
    min-width: 355px;
  }


  .light-bg-block {
    display: flex;
    flex-wrap: wrap;
  }

  .scoped-margin{
    margin: 10px 0;
  }

  .btn-left-block{

  }

  @include for-1300{
    .btn-left-block__list{
      display: flex;
      flex-direction: column-reverse;

    }

    .ml-0-adaptive{
      margin-left: 0!important;
    }

    .btn-right-block{
      width: fit-content;
    }
  }

  @include for-992{
    .btn-right-block{
      flex-wrap: wrap;
    }

    .btn-left-block{
      padding-left: 0;
    }
  }

  @include from-992{
    .content-top-line{
      margin-top: 0;
    }
  }

  @include for-992 {
    .btn-left-block {
      margin-bottom: 15px;
    }

    .btn-left-block__list__i{
      //flex-wrap: wrap;
      width: 100%;
    }
    //.min-vw-335px{
      //min-width: 250px;

      //.main-button{
      //  margin-left: 0 !important;
      //  margin-top: 10px;
      //}
    //}

    .btn-right-block {
      //flex-wrap: wrap;
    }

    .main-button {
      margin-bottom: 10px;
    }

  }

  @include for-550 {

    .btn-right-block{
      width: 100%;
    }

    .btn-right-block--mob{
      margin-bottom: -10px;
    }

    .btn-right-block .main-button.btn-fit-content{
      width: 100% !important;
      margin-left: 0!important;
    }

    .btn-right-block > a{
      width: 100%;
    }

    .btn-left-block,
    .btn-left-block__list{
      width: 100%;
    }

    .btn-left-block__list__i{
      flex-wrap: wrap;
    }
    .min-vw-335px{
      min-width: unset;
      .main-button{
        margin-left: 0 !important;
        margin-top: 10px;
        width: 100%;
      }
    }


  }

  @include for-420 {
    .light-bg-block {
      //flex-direction: column-reverse;
      //align-items: flex-start;
    }
  }

</style>


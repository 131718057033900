<template>
  <div class="fragment">
    <div class="section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['requestFunds_Status',])"></div>
      {{ $t('requestFunds_Status.localization_value.value') }}
    </div>
    <div class="custom-row">
      <div class="custom-col custom-col--33 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
        'requestFunds_Status',
        REQUEST_FUNDS_STATUSES.SUCCESS_OUTPUT_STATUS.translation,
        REQUEST_FUNDS_STATUSES.NEW_OUTPUT_STATUS.translation,
        REQUEST_FUNDS_STATUSES.CANCELED_OUTPUT_STATUS.translation,
        ])"></div>
        <DefaultSelect
            v-if="_.has(RF.data.accountType, 'bank')"
            :options="options"
            :label="$t('requestFunds_Status.localization_value.value')"
            v-bind:class="{'ui-no-valid': RF.validation.status}"
            :errorTxt="$t(`${RF.validationTranslate.status}.localization_value.value`)"
            :error="RF.validation.status"
            :selected="RF.data.status"
            :clearable="false"
            :disabled="RF.data.statusLock &&
              (RF.data.accountType.bank.id === ACCOUNT_BANK_PAYONEER.id || RF.data.accountType.bank.id === ACCOUNT_BANK_PRIVAT.id)"
            :otherValue="'translation'"
            @change="changeStatus"
        />
      </div>
    </div>

    <div class="custom-row">
      <div class="custom-col"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['requestFunds_ignoreAmountLimit',])"></div>
        <DefaultCheckbox
            :label="$t('requestFunds_ignoreAmountLimit.localization_value.value')"
            v-model="RF.data.ignoreAmountLimit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultCheckbox from "../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {
  ACCOUNT_BANK_PAYONEER, ACCOUNT_BANK_PRIVAT,
  REQUEST_FUNDS_STATUSES,
  REQUEST_FUNDS_STATUSES_ARRAY
} from "../../../../../../staticData/staticVariables";

export default {
  name: "AdminFields",
  components: {DefaultSelect, DefaultCheckbox},

  props: {
    RF: Object,
  },

  data() {
    return {
      REQUEST_FUNDS_STATUSES: REQUEST_FUNDS_STATUSES,
      options: REQUEST_FUNDS_STATUSES_ARRAY,
      ACCOUNT_BANK_PAYONEER: ACCOUNT_BANK_PAYONEER,
      ACCOUNT_BANK_PRIVAT: ACCOUNT_BANK_PRIVAT,
    }
  },

  methods: {
    changeStatus(val) {
      this.RF.setStatus(val)
    }
  }

}
</script>

<style scoped>

</style>
